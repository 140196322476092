import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    chatNotif: [],
    selectedHostId: 0,
};

export const wsSlice = createSlice({
    name: "wsSlice",
    initialState,
    reducers: {
        setChatNotif: (state, action) => {
            state.chatNotif.push(action.payload);
        },
        removeChatNotif: (state, action) => {
            const filtered = state.chatNotif.filter((e) => e.HostId !== action.payload);
            state.chatNotif = filtered;
        },
        setSelectedHostId: (state, action) => {
            state.selectedHostId = action.payload;

            // remove chats by host id
            const filtered = state.chatNotif.filter((e) => e.HostId !== action.payload);
            state.chatNotif = filtered;
        },
    },
});

export const {
    setChatNotif,
    removeChatNotif,
    setSelectedHostId,
} = wsSlice.actions;

export default wsSlice.reducer;
