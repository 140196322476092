import { Alert, Button, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import CustomPasswordField from "./shared/custom_password";
import { setAuthenticationStatus, setLogInProcess, showLoader } from "../features/auth/authSlice";
import HouseCard from "./helper/HouseCard";

export default function ChangePassword({ currentUser }) {
    const theme = useTheme();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordIsValid, setPasswordIsValid] = useState(false);
    const [formIsComplete, setFormIsComplete] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [sessionExpired, setSessionExpired] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (password !== confirmPassword) setPasswordIsValid(false);
        else setPasswordIsValid(true);

        if (password.length !== 0
            && confirmPassword.length !== 0
            && (password === confirmPassword)) setFormIsComplete(true);
        else setFormIsComplete(false);
    }, [password, confirmPassword]);

    const handleChangePassword = async (user, newPassword) => {
        if (hasSubmitted) return;
        if (!formIsComplete) return;

        setErrorMessage("");
        setSessionExpired(false);

        dispatch(showLoader({ open: true, message: "Verifying, please wait..." }));
        try {
            const result = await Auth.completeNewPassword(user, newPassword);
            window.location.replace("/dashboard");
        } catch (error) {
            if (error.name === "InvalidPasswordException") setErrorMessage("Password is not long enough.");
            else if (error.name === "NotAuthorizedException") {
                setSessionExpired(true);
            } else setErrorMessage("An error has encountered while trying to change password. Please try again later.");
        } finally {
            dispatch(showLoader({ open: false, message: "" }));
        }
    };

    return (
        <HouseCard
            title="Change Password"
        >
            <Grid container spacing={2}>
                {errorMessage && (
                    <Grid item xs={12}>
                        <Alert severity="error">{errorMessage}</Alert>
                    </Grid>
                )}
                {sessionExpired && (
                    <Grid item xs={12}>
                        <Alert severity="error">
                            <Grid container spacing={1}>
                                <Grid item xs={12}><Typography>Your session has expired.</Typography></Grid>
                                <Grid item xs={12}><Typography variant="subtitle2">Please refresh the page.</Typography></Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={() => window.location.reload(true)}
                                    >
                                        Refresh
                                    </Button>
                                </Grid>
                            </Grid>
                        </Alert>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <CustomPasswordField
                        label="New Password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                    />
                    <Typography
                        component="div"
                        variant="caption"
                        sx={{ marginTop: theme.spacing(1) }}
                        color="primary"
                    >
                        Your password must contain both lowercase and uppercase letters, and at least one number.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <CustomPasswordField
                        label="Confirm New Password"
                        value={confirmPassword}
                        onChange={(e) => {
                            setConfirmPassword(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key !== "Enter") return;
                            handleChangePassword(currentUser, confirmPassword);
                        }}
                    />
                    <Typography
                        variant="caption"
                        color="error"
                    >
                        {!passwordIsValid && "Both password should be equal."}
                        &nbsp;
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        size="small"
                        fullWidth
                        value={confirmPassword}
                        onChange={(e) => {
                            setConfirmPassword(e.target.value);
                        }}
                        disabled={!formIsComplete}
                        onClick={() => {
                            handleChangePassword(currentUser, confirmPassword);
                        }}
                    >
                        Change Password
                    </Button>
                </Grid>
            </Grid>
        </HouseCard>
    );
}
