import { Send } from "@mui/icons-material";
import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    TextField,
    useTheme,
} from "@mui/material";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useUpdateServiceRequestStatusMutation } from "../../features/auth/spAPI";
import "../../utils/moment-setup";

function SendMessagetoHost({
    OpenMessageToHost,
    CloseMessageToHost,
    CloseServiceDetailsForm,
    PassIdFromDetails,
    PassStatusId,
    PassStatusName,
    PassSPRemarks }) {
    const [MessageTxt, setMessageTxt] = useState("");
    const theme = useTheme();

    // get timezone from user's settings (or set to local timezone if empty)
    const userTimezone = useSelector((state) => state.auth.userInfo.timeZone) || moment.tz.guess();

    const [updateServiceRequest,
        { isLoading: isLoadingUpdateServiceRequest,
            isError: isErrorUpdateServiceRequest,
            error: errorUpdateServiceRequest,
            isSuccess: isSuccessUpdateServiceRequest,
            reset: resetUpdateServiceRequest }] = useUpdateServiceRequestStatusMutation();

    useEffect(() => {
        if (isSuccessUpdateServiceRequest) {
            setMessageTxt("");
            CloseMessageToHost(false);
            CloseServiceDetailsForm(false);
        }
    }, [isSuccessUpdateServiceRequest]);

    const UpdateMessageFunction = () => {
        if (MessageTxt !== "") {
            updateServiceRequest({
                id: PassIdFromDetails,
                serviceStatusId: PassStatusId,
                lastUpdatedDateTime: moment.utc(),
                providerRemarks: `${PassSPRemarks}${`${moment().tz(userTimezone).format()}`},${PassStatusName},${PassStatusName},${JSON.stringify(MessageTxt)}\n`,
            });
        } else {
            CloseMessageToHost(false);
            CloseServiceDetailsForm(false);
        }
    };

    const isProcessing = isLoadingUpdateServiceRequest;

    return (
        <Dialog
            open={OpenMessageToHost}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                Send Message to Host
            </DialogTitle>
            <DialogContent>
                <TextField
                    sx={{ mt: theme.spacing(3) }}
                    multiline
                    minRows={3}
                    fullWidth
                    value={MessageTxt}
                    onChange={(e) => {
                        setMessageTxt(e.target.value);
                        if (e.target.value === "") {
                            window.alert("This field is empty.");
                        }
                    }}
                    variant="outlined"
                    disabled={isProcessing}
                />
            </DialogContent>
            {isProcessing && (<LinearProgress />)}
            <DialogActions>
                <Button
                    size="small"
                    variant="contained"
                    onClick={() => (CloseMessageToHost(false))}
                    color="close"
                    disabled={isProcessing}
                >
                    Close
                </Button>
                <Button
                    size="small"
                    onClick={UpdateMessageFunction}
                    variant="contained"
                    endIcon={<Send />}
                    disabled={isProcessing}
                >
                    <span>Send Message</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SendMessagetoHost;
