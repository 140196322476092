// https://redux-toolkit.js.org/rtk-query/overview
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import { STATUS_CODE_OTP_SUCCESS, STATUS_CODE_PROFILE_COMPLETE } from "../../utils/SignUpStatusCode";
import { setUserInfo, showLoader } from "./authSlice";

export const spAPI = createApi({
    reducerPath: "serviceRequestApi",
    baseQuery: fakeBaseQuery(),
    endpoints: (builder) => ({
        getUserProfile: builder.query({
            queryFn: async (props, {
                dispatch,
            }) => {
                try {
                    const profile = await API.get("ProviderApi", "/getProfile");

                    dispatch(setUserInfo(profile));

                    let redirectTo = "";
                    switch (profile.signUpStatusCode) {
                        case STATUS_CODE_OTP_SUCCESS:
                            redirectTo = "/complete-registration";
                            break;
                        case STATUS_CODE_PROFILE_COMPLETE:
                            redirectTo = "/dashboard";
                            break;
                        default:
                            break;
                    }
                    return {
                        data: {
                            profile,
                            redirectTo,
                        },
                    };
                } catch (error) {
                    return { error };
                }
            },
            providesTags: () => [{ type: "userprofile", id: "get" }],
        }),
        updateProfile: builder.mutation({
            queryFn: async ({
                profile,
            }, {
                dispatch,
            }) => {
                try {
                    const myInit = {
                        body: profile,
                    };
                    dispatch(showLoader({ open: true, message: "Updating your profile. Please wait..." }));
                    const resp = await API.post("ProviderApi", "/signUpProfileUpdate", myInit);
                    dispatch(setUserInfo(resp));
                    dispatch(showLoader({ open: false, message: "" }));

                    return {
                        data: resp,
                    };
                } catch (error) {
                    return {
                        error,
                    };
                }
            },
        }),
        getServices: builder.query({
            queryFn: async () => {
                try {
                    const response = await API.get("ServiceRequest", "/getListofServices");
                    return { data: response };
                } catch (error) {
                    return { error };
                }
            },
            providesTags: [{ type: "servicerequest", id: "list" }],
        }),
        getPendingJobs: builder.query({
            queryFn: async () => {
                try {
                    const response = await API.get("ServiceRequest", "/getPendingJobs");
                    return { data: response };
                } catch (error) {
                    return { error };
                }
            },
            providesTags: [{
                type: "pendingJobs",
                id: "list",
            }],
        }),
        getDetails: builder.query({
            queryFn: async ({ id }) => {
                try {
                    const response = await API.get("ServiceRequest", `/getServiceDetails/${id}`);
                    return { data: response };
                } catch (error) {
                    return error;
                }
            },
            providesTags: (id) => [{ type: "servicerequest", id }],
        }),
        getCompletedService: builder.query({
            queryFn: async ({ id }) => {
                try {
                    const response = await API.get("ServiceRequest", `/getCompletedRequestDetails/${id}`);
                    return { data: response };
                } catch (error) {
                    return { error };
                }
            },
            providesTags: (id) => [{ type: "completedService", id }],
        }),
        getHostInvites: builder.query({
            queryFn: async (
                props,
                {
                    getState,
                },
            ) => {
                try {
                    const localUserTZ = getState().auth.userInfo.timeZone;

                    const response = await API.get("ServiceRequest", "/GetInvites");

                    for (let index = 0; index < response.length; index++) {
                        const element = response[index];

                        if (element.requestDate !== null) {
                            const dtRequest = moment.tz(element.requestDate, localUserTZ);
                            element.convertedDateOfRequest = dtRequest.format("MM/DD/YYYY");
                            element.convertedTimeOfRequest = dtRequest.format("hh:mm A");
                        } else {
                            element.convertedDateOfRequest = "";
                            element.convertedTimeOfRequest = "";
                        }
                    }

                    return { data: response };
                } catch (error) {
                    return { error };
                }
            },
            providesTags: (id) => [{
                type: "hostInvites",
                id: "list",
            }],
        }),
        updateServiceRequestStatus: builder.mutation({
            queryFn: async ({ id, serviceStatusId, lastUpdatedDateTime, providerRemarks }) => {
                try {
                    const bodyContent = {
                        body: {
                            id,
                            serviceStatusId,
                            lastUpdatedDateTime,
                            providerRemarks,
                        },
                    };
                    const response = await API.put("ServiceRequest", "/updateServiceRequestStatus", bodyContent);

                    return { data: response };
                } catch (error) {
                    return error;
                }
            },
            invalidatesTags: (id) => [
                {
                    type: "servicerequest",
                    id: "list",
                },
                {
                    type: "servicerequest",
                    id,
                },
                {
                    type: "completedService",
                    id,
                },
                {
                    type: "pendingJobs",
                    id: "list",
                },
            ],
        }),
        updateInvite: builder.mutation({
            queryFn: async ({ id, status }) => {
                try {
                    const bodyContent = {
                        body: {
                            id,
                            status,
                        },
                    };
                    const response = await API.put("ServiceRequest", "/updateHostReferenceStatus", bodyContent);

                    return { data: response };
                } catch (error) {
                    return error;
                }
            },
            invalidatesTags: () => [
                {
                    type: "hostInvites",
                    id: "list",
                },
                {
                    type: "servicerequest",
                    id: "list",
                },
            ],
        }),
    }),
});

export const {
    useGetUserProfileQuery,
    useLazyGetUserProfileQuery,
    useUpdateProfileMutation,
    useGetServicesQuery,
    useGetPendingJobsQuery,
    useGetDetailsQuery,
    useGetHostInvitesQuery,
    useGetCompletedServiceQuery,
    useUpdateServiceRequestStatusMutation,
    useUpdateInviteMutation,
} = spAPI;
