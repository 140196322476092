import { Grid, List, ListItem, ListItemButton, Skeleton, Stack, TextField } from "@mui/material";
import { Geo } from "aws-amplify";
import { useEffect, useState } from "react";

export default function TestMapComponent() {
    const [locSearch, setLocSearch] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);

    useEffect(() => {
        const delayDbounceFn = setTimeout(() => {
            if (locSearch.trim().length > 0) {
                setSearchLoading(true);
                const searchOptions = {
                    maxResults: 5,
                };
                Geo.searchByText(locSearch, searchOptions)
                    .then((d) => {
                        setSearchResult(d);
                        setSearchLoading(false);
                    }).finally(() => setSearchLoading(false));
            }
        }, 3000);
        return () => clearTimeout(delayDbounceFn);
    }, [locSearch]);

    const handleSearchLocation = (e) => {
        setLocSearch(e.target.value);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <TextField
                    label="Location"
                    size="small"
                    fullWidth
                    onChange={handleSearchLocation}
                    focused
                />
                {searchLoading ? (
                    <Stack>
                        <Skeleton
                            width="100%"
                            sx={{ padding: 1, textAlign: "center" }}
                            variant="rounded"
                        >
                            Loading...
                        </Skeleton>
                    </Stack>
                ) : (
                    <List>
                        {searchResult.map((d) => (
                            <ListItem
                                disableGutters
                                disablePadding
                                key={d.label}
                            >
                                <ListItemButton>
                                    {d.label}

                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                )}
            </Grid>
        </Grid>
    );
}
