// https://www.geeksforgeeks.org/reactjs-uselocalstorage-custom-hook/
import { useState } from "react";

// like useState but stores value in local storage
// checks local storage if value already exists first
// if not existing, set to default value
// if already exists in local storage but value is not in the array of possibleValues, set to default value
// otherwise use the existing value

const useLocalStorage = (key, defaultValue, possibleValues) => {
    // Create state variable to store
    // localStorage value in state
    const [localStorageValue, setLocalStorageValue] = useState(() => {
        try {
            const value = localStorage.getItem(key);
            // If value is already present in
            // localStorage then return it
            // Else set default value in
            // localStorage and then return it
            if (value) {
                if (Array.isArray(possibleValues)) {
                    if (possibleValues.includes(JSON.parse(value))) {
                        return JSON.parse(value);
                    }
                    localStorage.setItem(key, JSON.stringify(defaultValue));
                    return defaultValue;
                }
                return JSON.parse(value);
            }
            localStorage.setItem(key, JSON.stringify(defaultValue));
            return defaultValue;
        } catch (error) {
            localStorage.setItem(key, JSON.stringify(defaultValue));
            return defaultValue;
        }
    });

    // this method update our localStorage and our state
    const setLocalStorageStateValue = (valueOrFn) => {
        let newValue;
        if (typeof valueOrFn === "function") {
            const fn = valueOrFn;
            newValue = fn(localStorageValue);
        } else {
            newValue = valueOrFn;
        }
        localStorage.setItem(key, JSON.stringify(newValue));
        setLocalStorageValue(newValue);
    };
    return [localStorageValue, setLocalStorageStateValue];
};

export default useLocalStorage;
