import { Alert, Box, Button, Divider, Grid, Typography, useTheme } from "@mui/material";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setSignUpProcess, showLoader } from "../features/auth/authSlice";
import VerifyOtp from "./VerifyOtp";
import PhoneInput from "./helper/react-phone-input-2";
import CustomPasswordField from "./shared/custom_password";
import { SIGNUP_STEP, SIGNUP_VERIFYOTP_STEP } from "../utils/enum";
import CustomPhoneInput from "./shared/custom_phoneinput";
import HouseCard from "./helper/HouseCard";

export default function SignUp() {
    const theme = useTheme();
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const currentSignUpProcess = useSelector((state) => state.auth.signUpProcess);
    const dispatch = useDispatch();
    const [session, setSession] = useState(null);
    const [errorPhoneField, setErrorPhoneField] = useState("");
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const [passwordIsValid, setPasswordIsValid] = useState(false);
    const [formIsComplete, setFormIsComplete] = useState(false);

    useEffect(() => {
        dispatch(setSignUpProcess(SIGNUP_STEP));
    }, []);

    useEffect(() => {
        if (phone === null) return;
        if (password !== confirmPassword) setPasswordIsValid(false);
        else setPasswordIsValid(true);

        if (phone.length !== 0
            && password.lenght !== 0
            && confirmPassword !== 0
            && (password === confirmPassword)) setFormIsComplete(true);
        else setFormIsComplete(false);
    }, [phone, password, confirmPassword]);

    const handleSignUp = async () => {
        if (hasSubmitted) return;
        setHasSubmitted(true);
        const phoneWithPlus = phone[0] === "+" ? phone : `+${phone}`;
        dispatch(showLoader({ open: true, message: "Signing Up. Please wait..." }));
        setErrorPhoneField("");
        try {
            const resp = await Auth.signUp({
                username: phone.replace("+", "").replace(/\s+/g, "").trim(),
                password,
                autoSignIn: {
                    enabled: true,
                },
                attributes: {
                    phone_number: phoneWithPlus,
                },
            });

            dispatch(setSignUpProcess(SIGNUP_VERIFYOTP_STEP));
        } catch (err) {
            setHasSubmitted(false);
            dispatch(showLoader({ open: false, message: "" }));
            if (err.code === "UsernameExistsException") {
                setErrorPhoneField("User already exists.");
            } else if (err.message === "Username cannot be empty") {
                setErrorPhoneField(err.message);
            } else if (err.name === "InvalidPasswordException") {
                setErrorPhoneField("Password is not long enough.");
            } else if (err.name === "InvalidParameterException") {
                setErrorPhoneField(err.message);
            } else {
                setErrorPhoneField("An error has encountered while trying to change password. Please try again later.");
            }
        } finally {
            dispatch(showLoader({ open: false, message: "" }));
        }
    };

    // const handleEnterKeyPress = () => {
    //     if (hasSubmitted) return;
    //     handleSignUp();
    // };

    return (
        <>
            {currentSignUpProcess === SIGNUP_STEP && (
                <HouseCard
                    title="Sign Up"
                >

                    <Grid container spacing={2}>
                        {(errorPhoneField !== "") && (
                            <Grid item xs={12}>
                                <Alert severity="error" onClose={() => setErrorPhoneField("")}>{errorPhoneField}</Alert>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <CustomPhoneInput
                                label="Phone"
                                fullWidth
                                onChange={(e) => {
                                    setPhone(e);
                                    setErrorPhoneField("");
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomPasswordField
                                label="Password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                            <Typography
                                component="div"
                                variant="caption"
                                sx={{ marginTop: theme.spacing(1) }}
                                color="primary"
                            >
                                Your password must contain both lowercase and uppercase letters, and at least one number.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomPasswordField
                                label="Re-enter Password"
                                value={confirmPassword}
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key !== "Enter") return;
                                    handleSignUp();
                                }}
                            />
                            <Typography
                                variant="caption"
                                color="error"
                            >
                                {!passwordIsValid && "Both password should be equal."}
                                &nbsp;
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                size="small"
                                fullWidth
                                disabled={!formIsComplete}
                                onClick={() => handleSignUp()}
                            >
                                Sign Up
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Typography variant="caption" component="div">
                                Already have an account?&nbsp;
                                <Link to="/login">Log In</Link>
                            </Typography>
                            <Typography variant="caption" component="div">
                                Sign Up as a&nbsp;
                                <Link to={`${process.env.REACT_APP_HOST_URL}/signup`}>Host</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </HouseCard>
            )}
            {currentSignUpProcess === SIGNUP_VERIFYOTP_STEP && <VerifyOtp phoneNumber={phone} />}
        </>
    );
}
