import {
    Badge,
    Box, Grid,
    Paper,
    styled,
    Tab,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useLocalStorage from "../utils/useLocalStorage";
import Listofservices from "./services/listofservices";
import Pendingjobs from "./services/pendingjobs";
import Hostinvites from "./services/hostinvites";
import RefreshOptions from "./helper/RefreshOptions";
import {
    useGetServicesQuery,
    useGetPendingJobsQuery,
    useGetHostInvitesQuery,
    spAPI,
} from "../features/auth/spAPI";

const refreshChoices = [
    { interval: 15000, text: "15s" },
    { interval: 60000, text: "1m" },
    { interval: 300000, text: "5m" },
    { interval: 900000, text: "15m" },
    { interval: 3600000, text: "1h" },
];

const possibleIntervalValues = refreshChoices.map((r) => r.interval);

const StyledTab = styled(Tab)(({ theme }) => ({
    "& .MuiBadge-root": {
        position: "absolute",
        top: theme.spacing(2),
        right: theme.spacing(1.5),
    },
}));

function Dashboard() {
    const dispatch = useDispatch();
    const [value, setValue] = useState("1");
    const userInfo = useSelector((state) => state.auth.userInfo);
    const [refreshInterval, setRefreshInterval] = useLocalStorage(
        "refreshInterval",
        900000, // 900000 ms = 15 minutes
        possibleIntervalValues,
    );

    useEffect(() => {
        const timer = setInterval(() => {
            refetchAll();
        }, refreshInterval);

        return () => {
            clearInterval(timer);
        };
    }, [refreshInterval]);

    const {
        data: dataServiceRequests,
        refetch: refetchServiceRequests,
    } = useGetServicesQuery();
    const {
        data: dataPendingJobs,
        refetch: refetchPendingJobs,
    } = useGetPendingJobsQuery();
    const {
        data: dataHostInvites,
        refetch: refetchHostInvites,
    } = useGetHostInvitesQuery();
    const refetchAll = () => {
        dispatch(spAPI.util.invalidateTags([
            { type: "servicerequest", id: "list" },
            { type: "pendingJobs", id: "list" },
            { type: "hostInvites", id: "list" },
        ]));
    };

    // const TabStatusListofServices = dataServiceRequests?.length || 0;
    const TabStatusPendingJobs = dataPendingJobs?.length || 0;
    const TabStatusHostInvites = dataHostInvites?.length || 0;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid container spacing={2} border={0}>
            <Grid item xs={12} border={0} style={{ marginBottom: "-8px" }}>
                <RefreshOptions
                    refreshChoices={refreshChoices}
                    refreshInterval={refreshInterval}
                    setRefreshInterval={setRefreshInterval}
                    refetch={refetchAll}
                />
            </Grid>
            <Grid item xs={12} border={0}>
                <Paper>
                    <TabContext value={value}>
                        <Box>
                            <TabList
                                scrollButtons="auto"
                                variant="scrollable"
                                visibleScrollbar
                                allowScrollButtonsMobile
                                onChange={handleChange}
                            >
                                <StyledTab
                                    label="Service Requests"
                                    value="1"
                                    icon={(
                                        <Badge
                                            badgeContent={0 /* TabStatusListofServices */}
                                            color="error"
                                        />
                                    )}
                                    iconPosition="end"

                                />
                                <StyledTab
                                    label="Pending Jobs"
                                    value="2"
                                    icon={(
                                        <Badge
                                            badgeContent={TabStatusPendingJobs}
                                            color="error"
                                        />
                                    )}
                                />
                                <StyledTab
                                    label="Invites"
                                    value="3"
                                    icon={(
                                        <Badge
                                            badgeContent={TabStatusHostInvites}
                                            color="error"
                                        />
                                    )}
                                />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Listofservices />
                        </TabPanel>
                        <TabPanel value="2">
                            <Pendingjobs />
                        </TabPanel>
                        <TabPanel value="3">
                            <Hostinvites />
                        </TabPanel>
                    </TabContext>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default Dashboard;
