import { HomeRepairService } from "@mui/icons-material";
import { Paper, Typography, useTheme } from "@mui/material";

export default function EmptyState({
    desc,
}) {
    const theme = useTheme();
    return (
        <Paper
            variant="outlined"
            sx={{
                padding: theme.spacing(2),
                textAlign: "center",
            }}
        >
            <HomeRepairService
                sx={{
                    fontSize: theme.spacing(10),
                    color: theme.palette.grey[500],
                }}
            />
            <Typography
                variant="body2"
                sx={{
                    textAlign: "center",
                    color: theme.palette.grey[500],
                }}
            >
                {desc}
            </Typography>
        </Paper>
    );
}
