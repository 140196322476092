import moment from "moment-timezone";

/*
    Override zz token
    Main issue is that timezone abbreviations are not unique (https://momentjs.com/timezone/docs/)
    PST can be Pacific Standard Time or Philippine Standard Time
    If timezone is in America, return abbreviation. otherwise return UTC offset
    Example usage:
    moment().tz("America/New_York").format("zz") => "EST" or "EDT"
    moment().tz(moment.tz.guess()).format("zz") => "UTC+08:00"
*/
moment.fn.zoneName = function () {
    const abbr = this.zoneAbbr();
    if (this.tz() && this.tz().includes("America")) return abbr;
    return this.format("UTCZ");
};
