import { CircularProgress, Grid, Paper, Stack, TextField, Typography, styled, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Geo } from "aws-amplify";
import { LoadingButton } from "@mui/lab";

const AddressItem = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: "left",
    backgroundColor: theme.palette.grey[100],
    boxShadow: theme.shadows[0],
    minHeight: 64,
}));

export default function CustomLocationFormGroup({
    addressProp,
    timeZoneProp,
    callbackResult,
}) {
    const theme = useTheme();
    const searchOptionsWithBiasPosition = { maxResults: 1 };

    const [Address, setAddress] = useState("");
    const [AddressforSearch, setAddressforSearch] = useState("");
    const mapInitialState = {
        latitude: 0,
        longitude: 0,
        label: "",
        country: "",
        municipality: "",
        postalCode: "",
        region: "",
        street: "",
        timeZone: "",
    };

    const [mapLocation, setMapLocation] = useState(mapInitialState);

    const [result, setResult] = useState({
        Address,
        mapLocation,
    });

    const [disableConfirmLocationButton, setDisableConfirmLocationButton] = useState(false);

    const [isSearchLocationLoading, setIsSearchLocationLoading] = useState(false);

    const PerformLocationSearch = async () => {
        if (AddressforSearch !== "") {
            try {
                setIsSearchLocationLoading(true);
                const data = await Geo.searchByText(AddressforSearch, searchOptionsWithBiasPosition);
                setMapLocation({
                    ...mapLocation,
                    country: data[0].country || "",
                    longitude: data[0].geometry.point[0],
                    latitude: data[0].geometry.point[1],
                    municipality: data[0].municipality || "",
                    region: data[0].region || "",
                    postalCode: data[0].postalCode || "",
                    timeZone: data[0].timeZone.name || "",
                });
                setResult((prev) => ({
                    ...prev,
                    Address: AddressforSearch,
                    mapLocation: {
                        country: data[0].country || "",
                        longitude: data[0].geometry.point[0],
                        latitude: data[0].geometry.point[1],
                        municipality: data[0].municipality || "",
                        region: data[0].region || "",
                        postalCode: data[0].postalCode || "",
                        timeZone: data[0].timeZone.name || "",
                    },
                }));
                setDisableConfirmLocationButton(true);
                setIsSearchLocationLoading(false);
            } catch (error) {
                setIsSearchLocationLoading(false);
            }
        }
    };

    useEffect(() => {
        if (addressProp !== undefined) {
            if (addressProp.trim().length !== 0) { setAddress(addressProp); }
        }

        if (timeZoneProp !== undefined) {
            if (timeZoneProp.trim().length) {
                setMapLocation((prev) => ({
                    ...prev,
                    timeZone: timeZoneProp,
                }));
            }
        }
    }, [addressProp, timeZoneProp]);

    useEffect(() => {
        if (result.Address.trim().length > 0) {
            if (callbackResult !== undefined) callbackResult(result);
        }
    }, [result]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Stack
                    direction={{
                        xs: "column",
                        sm: "row",
                    }}
                    spacing={1}
                >
                    <TextField
                        onChange={(e) => {
                            setDisableConfirmLocationButton(false);
                            setAddress(e.target.value);
                            if (e.target.value.length > 5) {
                                setAddressforSearch(e.target.value);
                            } else {
                                setAddressforSearch("");
                            }
                        }}
                        fullWidth
                        label="Street Address"
                        size="small"
                        value={Address}
                        error={Address.trim().length === 0}
                        helperText={Address.trim().length === 0 && "* Property address is required."}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") PerformLocationSearch();
                        }}
                        onBlur={() => PerformLocationSearch()}
                    />
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        sx={{
                            height: 40,
                            minWidth: 180,
                        }}
                        disabled={disableConfirmLocationButton}
                        onClick={() => PerformLocationSearch()}
                        loadingIndicator={(
                            <CircularProgress
                                size={theme.spacing(2)}
                            />
                        )}
                        loading={isSearchLocationLoading}
                    >
                        Confirm Location
                    </LoadingButton>
                </Stack>
            </Grid>
            <Grid item xs={6} lg={3}>
                <AddressItem>
                    <Typography
                        variant="caption"
                        fontWeight="bold"
                    >
                        Timezone
                    </Typography>
                    <Typography
                        component="div"
                        variant="caption"
                    >
                        {isSearchLocationLoading ? (
                            <CircularProgress
                                size={theme.spacing(2)}
                            />
                        )
                            : mapLocation.timeZone === "" ? "-" : mapLocation.timeZone}
                    </Typography>
                </AddressItem>
            </Grid>
            <Grid item xs={6} lg={3}>
                <AddressItem>
                    <Typography
                        variant="caption"
                        fontWeight="bold"
                    >
                        Country
                    </Typography>
                    <Typography
                        component="div"
                        variant="caption"
                    >
                        {isSearchLocationLoading ? (
                            <CircularProgress
                                size={theme.spacing(2)}
                            />
                        )
                            : mapLocation.country === "" ? "-" : mapLocation.country}
                    </Typography>
                </AddressItem>
            </Grid>
            <Grid item xs={6} lg={3}>
                <AddressItem>
                    <Typography
                        variant="caption"
                        fontWeight="bold"
                    >
                        City
                    </Typography>
                    <Typography
                        component="div"
                        variant="caption"
                    >
                        {isSearchLocationLoading ? (
                            <CircularProgress
                                size={theme.spacing(2)}
                            />
                        )
                            : mapLocation.municipality === "" ? "-" : mapLocation.municipality}
                    </Typography>
                </AddressItem>
            </Grid>
            <Grid item xs={6} lg={3}>
                <AddressItem>
                    <Typography
                        variant="caption"
                        fontWeight="bold"
                    >
                        State
                    </Typography>
                    <Typography
                        component="div"
                        variant="caption"
                    >
                        {isSearchLocationLoading ? (
                            <CircularProgress
                                size={theme.spacing(2)}
                            />
                        )
                            : mapLocation.region === "" ? "-" : mapLocation.region}
                    </Typography>
                </AddressItem>
            </Grid>
        </Grid>
    );
}
