import { createTheme } from "@mui/material/styles";

const palette = {
    mode: "light",
    background: {
        default: "#ecf5f2",
    },
    close: {
        main: "#BDBDBD",
    },
    primary: {
        main: "#339966",
    },
    orange: {
        main: "#F39C12",
    },
    label: {
        main: "#718EBF",
    },
    text: {
        secondary: "#718EBF",
    },
};

const myTheme = createTheme({
    palette,
    typography: {
        h5: {
            color: palette.primary.main,
        },
        h6: {
            color: palette.primary.main,
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: "none",
                    color: theme.palette.text.primary,
                    "& svg": {
                        color: theme.palette.grey[600],
                    },
                    "& .MuiTypography-root": {
                        color: theme.palette.grey[100],
                    },
                }),
            },
        },
        MuiInputBase: {
            styleOverrides: {
                // input: ({ ownerState, theme }) => ({
                //     color: theme.palette.primary.main,
                // }),
                root: ({ ownerState, theme }) => ({
                    "& svg": {
                        color: theme.palette.primary.main,
                    },
                    ...(ownerState.disabled && {
                        "& svg": theme.palette.action.disabled,
                    }),
                }),
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderBottomStyle: "solid",
                    borderBottomWidth: 1,
                    borderBottomColor: theme.palette.grey[400],
                    fontSize: theme.spacing(2),
                    fontWeight: theme.typography.fontWeightBold,
                }),
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderTopStyle: "solid",
                    borderTopWidth: 1,
                    borderTopColor: theme.palette.grey[400],
                }),
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderBottomWidth: 1,
                    borderBottomStyle: "solid",
                    borderBottomColor: theme.palette.background.default,
                }),
            },
        },
    },
});

export default myTheme;
