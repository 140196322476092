import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Profile from "./Profile";

export default function RequiredInfo() {
    const navigate = useNavigate();
    const signUpStatus = useSelector((state) => state.auth.signUpProcessFlow);

    // useEffect(() => {
    //     console.log("RequiredInfo", signUpStatus);
    //     if (signUpStatus.statusCode !== 0) { navigate("/dashboard"); }
    // }, [signUpStatus]);

    const callbackSave = () => {
        window.location.replace("/dashboard");
    };
    return (
        <Profile
            callbackSave={callbackSave}
        />
    );
}
