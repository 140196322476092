import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import authReducer from "./features/auth/authSlice";
import fetchDataReducer from "./features/auth/fetchDataSlice";
import wsReducer from "./features/auth/websocketSlice";
import { spAPI } from "./features/auth/spAPI";

const store = configureStore({
    reducer: {
        auth: authReducer,
        ws: wsReducer,
        fetchData: fetchDataReducer,
        [spAPI.reducerPath]: spAPI.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(spAPI.middleware),
});

export default store;

setupListeners(store.dispatch);
