import { Badge, Grid, IconButton, List, ListItem, ListItemButton, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useGetChatListQuery, useGetChatMessageQuery } from "../../features/auth/wsAPI";
import MessageHistory from "./MessageHistory";
import { setSelectedHostId } from "../../features/auth/websocketSlice";

export default function MessengerPage() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [selectedHost, setSelectedHost] = useState(0);
    // for mobile display
    const [showChatHistory, setShowChatHistory] = useState(false);
    const [hostList, setHostList] = useState([]);
    const chatNotif = useSelector((state) => state.ws.chatNotif);

    const {
        data: dataChatList,
        isLoading: isLoadingChatList,
        isFetching: isFetchingChatList,
    } = useGetChatListQuery(null, {
        refetchOnFocus: true,
        refetchOnReconnect: true,
    });

    const isGettingChatList = isLoadingChatList || isFetchingChatList;

    const {
        data: dataGetMessage,
    } = useGetChatMessageQuery();

    useEffect(() => {
        if (dataChatList !== undefined) {
            setSelectedHost(dataChatList[0]);
            dispatch(setSelectedHostId(dataChatList[0].hostFK));
            const arr = [];
            dataChatList.forEach((item) => {
                const msgIdx = dataGetMessage.findIndex((i) => i.HostId === item.hostFK);
                const _host = {
                    hostFK: item.hostFK,
                    name: item.name,
                    flag: msgIdx !== -1,
                };

                arr.push(_host);
            });
            setHostList(arr);
        }
    }, [dataChatList]);

    useEffect(() => {
        if (chatNotif && chatNotif.length > 0) {
            chatNotif.forEach((item) => {
                setHostList((prev) => prev.map((i) => (i.hostFK === item.HostId ? { ...i, flag: true } : { ...i, flag: false })));
            });
        } else {
            hostList.forEach((item) => {
                setHostList((prev) => prev.map((i) => ({ ...i, flag: false })));
            });
        }
    }, [chatNotif]);

    return (
        <Paper
            sx={{
                padding: theme.spacing(1),
            }}
        >
            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                        display: {
                            xs: showChatHistory ? "none" : "block",
                            sm: "block",

                        },
                    }}
                >
                    <Typography
                        variant="caption"
                        padding={0}
                        textAlign="right"
                        component="div"
                        paddingRight={theme.spacing(7)}
                        color={isGettingChatList ? theme.palette.common.black : "transparent"}
                    >
                        Getting Host list. Please wait...
                    </Typography>
                    <List>
                        {dataChatList !== undefined && hostList.map((item, i) => {
                            const idx = i;
                            return (
                                <ListItem
                                    key={idx}
                                    disablePadding
                                >
                                    <ListItemButton
                                        onClick={() => {
                                            setSelectedHost(item);
                                            dispatch(setSelectedHostId(item.hostFK));
                                            // dispatch(removeChatNotif(item.hostFK));
                                            setShowChatHistory(!showChatHistory);
                                        }}
                                        selected={selectedHost.hostFK === item.hostFK}
                                    >
                                        <Badge
                                            badgeContent=" "
                                            variant="dot"
                                            color="error"
                                            anchorOrigin={{
                                                horizontal: "right",
                                                vertical: "top",
                                            }}
                                            invisible={!item.flag}
                                        >
                                            {item.name}
                                        </Badge>
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                </Grid>
                {isMobile && (
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: {
                                xs: showChatHistory ? "block" : "none",
                            },
                        }}
                    >
                        <IconButton
                            onClick={() => setShowChatHistory(!showChatHistory)}
                        >
                            <ArrowBack />
                        </IconButton>
                        {selectedHost.name}
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                        display: {
                            xs: showChatHistory ? "block" : "none",
                            sm: "block",
                        },
                    }}
                >
                    <Paper
                        sx={{
                            marginTop: theme.spacing(1),
                            backgroundColor: theme.palette.grey[100],
                            boxShadow: "none",
                            borderRadius: theme.shape.borderRadius,
                            padding: theme.spacing(1),
                        }}
                    >
                        {selectedHost !== 0 && (
                            <MessageHistory
                                selectedHostId={selectedHost.hostFK}
                            />
                        )}
                    </Paper>
                </Grid>
            </Grid>

        </Paper>
    );
}
