import { useLocation, useNavigate } from "react-router-dom";
import { Badge, IconButton } from "@mui/material";
import { ChatBubble } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedHostId } from "../../features/auth/websocketSlice";

export default function ChatMenuButton() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const selectedHostId = useSelector((state) => state.ws.selectedHostId);
    const chatNotif = useSelector((state) => state.ws.chatNotif);
    const [hasNewMessage, setHasNewMessage] = useState(false);

    useEffect(() => {
        console.log("chatNotif", chatNotif);
        if (chatNotif && chatNotif.length > 0) {
            chatNotif.forEach((item) => {
                if (item.MessageType === "recepient" && item.HostId !== selectedHostId) {
                    setHasNewMessage(true);
                }
            });
        } else {
            setHasNewMessage(false);
        }
    }, [chatNotif]);

    useEffect(() => {
        if (location.pathname !== "/messenger") dispatch(setSelectedHostId(0));
    }, [location]);

    return (
        <IconButton
            onClick={() => {
                navigate("/messenger");
            }}
        >
            <Badge
                variant="dot"
                badgeContent="new"
                color="error"
                invisible={!hasNewMessage}
            >
                <ChatBubble />
            </Badge>
        </IconButton>

    );
}
