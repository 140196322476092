import CloseIcon from "@mui/icons-material/Close";
import {
    Alert,
    Box,
    Grid,
    IconButton,
    Paper,
    Skeleton,
    Snackbar,
    Typography,
    useTheme,
} from "@mui/material";
import moment from "moment-timezone";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetServicesQuery } from "../../features/auth/spAPI";
import { getServiceStatusColorCode } from "../helper/ServiceStatusConstants";
import ServiceDetails from "./serviceDetails";
import EmptyState from "../helper/EmptyState";

function Listofservices() {
    const [OpenServiceDetails, setOpenServiceDetails] = useState(false);
    const [GetserviceDetailsbyId, setServiceDetailsbyId] = useState();
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const {
        data: dataServiceRequests,
        isLoading: isLoadingServiceRequests,
        isFetching: isFetchingServiceRequests,
        isSuccess: isSuccessServiceRequests,
        isError: isErrorServiceRequests } = useGetServicesQuery();
    const theme = useTheme();

    const handleClosealert = () => {
        setOpenErrorAlert(false);
        setOpenServiceDetails(false);
    };

    const handleClose = () => {
        setOpenServiceDetails(false);
    };

    const action = (
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClosealert}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    );

    const isGettingData = isLoadingServiceRequests || isFetchingServiceRequests;

    // get timezone from user's settings (or set to local timezone if empty)
    const userTimezone = useSelector((state) => state.auth.userInfo.timeZone) || moment.tz.guess();

    return (
        <>
            <Snackbar
                open={openErrorAlert}
                onClose={() => { setOpenErrorAlert(true); }}
                action={action}
            >
                <Alert severity="error">An error has occured, please try again later. </Alert>
            </Snackbar>
            <Grid
                container
                spacing={1}
                sx={{
                    [theme.breakpoints.up("md")]: {
                        maxWidth: 800,
                        marginLeft: "auto",
                        marginRight: "auto",
                    },
                }}
            >
                {dataServiceRequests !== undefined && dataServiceRequests?.map((row) => {
                    const colorCode = getServiceStatusColorCode(row.serviceStatusId);

                    return (
                        <Grid
                            item
                            xs={12}
                            key={row.id}
                        >
                            <Paper
                                onClick={() => {
                                    setOpenServiceDetails(true);
                                    setServiceDetailsbyId(row.id);
                                }}
                                sx={{
                                    padding: theme.spacing(1),
                                    boxShadow: "none",
                                    borderWidth: 2,
                                    borderStyle: "solid",
                                    borderColor: theme.palette.divider,
                                    borderLeft: `${theme.spacing(1.5)} solid ${colorCode}`,
                                    cursor: "pointer",
                                }}
                            >
                                <Typography
                                    color="text.primary"
                                    variant="body1"
                                    fontWeight="bold"
                                >
                                    {`${moment.utc(row.requestDate).tz(userTimezone).format("llll")} - ${row.serviceTypeName}`}
                                </Typography>
                                <Box>
                                    <Typography
                                        variant="body2"
                                        component="span"
                                    >
                                        {`${row.hostName} - ${row.propertyAddress}`}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        variant="body2"
                                        component="span"
                                        sx={{
                                            color: colorCode,
                                        }}
                                    >
                                        {row.serviceStatusName}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    );
                })}

                {isGettingData && (
                    <Grid item xs={12}>
                        <Skeleton
                            variant="rectangular"
                            sx={{
                                height: theme.spacing(4),
                                borderRadius: "4px",
                            }}
                        />
                    </Grid>
                )}
                {(!isGettingData && dataServiceRequests?.length === 0) && (
                    <Grid item xs={12}>
                        <EmptyState
                            desc="No Services Found"
                        />
                    </Grid>
                )}

            </Grid>

            <ServiceDetails
                OpenServiceDetailsModal={OpenServiceDetails}
                CloseServiceDetailsModal={handleClose}
                PassServiceDetailsId={GetserviceDetailsbyId}
            />
        </>
    );
}

export default Listofservices;
