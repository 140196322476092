import { Card, CardContent, CardHeader, CardMedia, Typography, useTheme } from "@mui/material";

export default function HouseCard({
    children,
    title,
}) {
    const theme = useTheme();

    return (
        <Card
            sx={{
                maxWidth: theme.spacing(60),
                marginLeft: "auto",
                marginRight: "auto",
            }}
        >
            <CardMedia
                component="img"
                image="/images/login-signup-img.jpg"
                sx={{
                    padding: theme.spacing(2),
                    height: theme.spacing(30),
                }}
            />
            <CardContent
                sx={{
                    paddingLeft: theme.spacing(6),
                    paddingRight: theme.spacing(6),
                }}
            >
                <Typography
                    variant="h6"
                    pb={theme.spacing(4)}
                    fontWeight={theme.typography.fontWeightBold}
                >
                    {title}
                </Typography>
                {children}
            </CardContent>
        </Card>
    );
}
