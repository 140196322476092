import { Dialog, DialogContent, DialogTitle, Grid, IconButton, CircularProgress, LinearProgress, Box, useTheme, useMediaQuery, DialogActions, Button, Typography, styled, Divider, Card, CardContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import moment from "moment-timezone";
import "../../utils/moment-setup";
import { useSelector } from "react-redux";
import NoImage from "../../assets/no-image.svg";
import { useGetCompletedServiceQuery } from "../../features/auth/spAPI";
import ServiceDetailsRemarks from "./serviceDetailsRemarks";

const StyledGridDetails = styled(Grid)(({ theme }) => ({
    ".label": {
        display: "block",
        [theme.breakpoints.up("sm")]: {
            display: "inline",
        },
    },
    ".value": {
        display: "block",
        paddingLeft: theme.spacing(1),
        [theme.breakpoints.up("sm")]: {
            display: "inline",
        },
    },
}));

function ViewCompletionForm({ OpenCompletedDialog, CloseCompletedDialog, PassServiceDetailsId }) {
    const [getRoomDetails, setGetRoomDetails] = useState([]);
    const [getServiceInfo, setGetServiceInfo] = useState([]);
    const theme = useTheme();
    const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const { data: dataCommpletedService,
        error: errorCompletedService,
        status: statusCompletedService,
        isLoading: isLoadingCompletedService } = useGetCompletedServiceQuery(
        { id: PassServiceDetailsId },
        { skip: !OpenCompletedDialog },
    );

    // get timezone from user's settings (or set to local timezone if empty)
    const userTimezone = useSelector((state) => state.auth.userInfo.timeZone) || moment.tz.guess();

    useEffect(() => {
        if (dataCommpletedService !== null && dataCommpletedService !== undefined) {
            setGetServiceInfo(dataCommpletedService);
            setGetRoomDetails(dataCommpletedService.propertyRooms);
        }
    }, [dataCommpletedService]);

    return (
        <Dialog
            open={OpenCompletedDialog}
            maxWidth="lg"
            fullScreen={isFullScreen}
        >
            <DialogTitle>
                Report
            </DialogTitle>
            <DialogContent>
                <br />

                {getServiceInfo.id === PassServiceDetailsId && (
                    <Grid container spacing={1} key={getServiceInfo.id}>
                        <Grid item xs={12} md={6}>
                            <Typography
                                component="span"
                                className="label"
                                variant="body2"
                                color="text.secondary"
                            >
                                Host Name:
                                {" "}
                            </Typography>
                            <Typography
                                component="span"
                                className="value"
                                fontWeight={600}
                            >
                                {getServiceInfo.hostName}
                            </Typography>
                        </Grid>
                        <StyledGridDetails item xs={12} md={6}>
                            <Typography
                                component="span"
                                variant="body2"
                                color="text.secondary"
                                className="label"
                            >
                                Property:
                                {" "}
                            </Typography>
                            <Typography
                                variant="body1"
                                component="span"
                                className="value"
                            >
                                {`${getServiceInfo.propertyName} - ${getServiceInfo.propertyAddress}`}
                            </Typography>
                        </StyledGridDetails>
                        <StyledGridDetails item xs={12} md={6}>
                            <Typography
                                component="span"
                                variant="body2"
                                color="text.secondary"
                                className="label"
                            >
                                Status:
                                {" "}
                            </Typography>
                            <Typography
                                variant="body1"
                                component="span"
                                className="value"
                            >
                                {getServiceInfo.serviceStatusName}
                            </Typography>
                        </StyledGridDetails>
                        <StyledGridDetails item xs={12} md={6}>
                            <Typography
                                component="span"
                                variant="body2"
                                color="text.secondary"
                                className="label"
                            >
                                Request Date:
                                {" "}
                            </Typography>
                            <Typography
                                variant="body1"
                                component="span"
                                className="value"
                            >
                                {`${moment.utc(getServiceInfo.requestDate).tz(userTimezone).format("LLLL zz")} (${userTimezone})`}
                            </Typography>
                        </StyledGridDetails>
                        <StyledGridDetails item xs={12} md={6}>
                            <Typography
                                component="span"
                                variant="body2"
                                color="text.secondary"
                                className="label"
                            >
                                Start Date:
                                {" "}
                            </Typography>
                            <Typography
                                variant="body1"
                                component="span"
                                className="value"
                            >
                                {`${moment.utc(getServiceInfo.startDateTime).tz(userTimezone).format("LLLL zz")} (${userTimezone})`}
                            </Typography>
                        </StyledGridDetails>
                        <StyledGridDetails item xs={12} md={6}>
                            <Typography
                                component="span"
                                variant="body2"
                                color="text.secondary"
                                className="label"
                            >
                                End Date:
                                {" "}
                            </Typography>
                            <Typography
                                variant="body1"
                                component="span"
                                className="value"
                            >
                                {`${moment.utc(getServiceInfo.endDateTime).tz(userTimezone).format("LLLL zz")} (${userTimezone})`}
                            </Typography>
                        </StyledGridDetails>
                        {getServiceInfo.hostRemarks && (
                            <Grid item xs={12}>
                                <Card
                                    sx={{
                                        boxShadow: "none",
                                        borderWidth: 1,
                                        borderStyle: "solid",
                                        borderColor: theme.palette.grey[400],
                                    }}
                                >
                                    <CardContent>
                                        {"Host Remarks: "}
                                        <Divider />
                                        <ServiceDetailsRemarks remarksString={getServiceInfo.hostRemarks} />
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Card
                                sx={{
                                    boxShadow: "none",
                                    borderWidth: 1,
                                    borderStyle: "solid",
                                    borderColor: theme.palette.grey[400],
                                }}
                            >
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary">{"Provider Remarks: "}</Typography>
                                    <Divider />
                                    <ServiceDetailsRemarks remarksString={getServiceInfo.providerRemarks} />
                                </CardContent>
                            </Card>

                        </Grid>
                    </Grid>
                )}
                <Divider />
                {getRoomDetails.map((item) => {
                    const imgURL = (item.imgURL === undefined || item.imgURL === "") ? NoImage : item.imgURL;
                    return (
                        <Grid container spacing={2} key={item.roomFK}>
                            <Grid item xs={6}>{`${item.roomLabel}`}</Grid>
                            <Grid item xs={6}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                        backgroundImage: `url(${imgURL})`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "center",
                                        height: theme.spacing(20),
                                    }}
                                >

                                    {
                                        item.isLoading && (<CircularProgress />)
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    );
                })}
                {isLoadingCompletedService && (
                    <>
                        <p>Loading Please wait...</p>
                        <LinearProgress />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="close"
                    onClick={CloseCompletedDialog}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ViewCompletionForm;
