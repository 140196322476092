import { API, Auth } from "aws-amplify";
import ReconnectingWebSocket from "reconnecting-websocket";
import { spAPI } from "./spAPI";
import { setChatNotif } from "./websocketSlice";

const urlProvider = async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    return `${process.env.REACT_APP_WEBSOCKET}?userType=sp&token=${token}`;
};

// let socket;

// function getWebSocket() {
//     return new ReconnectingWebSocket(urlProvider, null, {
//         debug: true,
//     });
// }

// https://www.npmjs.com/package/reconnecting-websocket
export const socket = new ReconnectingWebSocket(urlProvider, null, {
    debug: false,
});

const wsAPI = spAPI.injectEndpoints({
    endpoints: (builder) => ({
        getChatList: builder.query({
            queryFn: async () => {
                try {
                    const response = await API.get("WebSocketAPI", "/chatList");
                    return {
                        data: response,
                    };
                } catch (error) {
                    return { error };
                }
            },
            providesTags: [{
                type: "chatlist",
                id: "list",
            }],
        }),
        getChatMessage: builder.query({
            queryFn: () => ({ data: [] }),
            async onCacheEntryAdded(arg, {
                cacheDataLoaded,
                cacheEntryRemoved,
                updateCachedData,
                dispatch,
                getState,
            }) {
                try {
                    await cacheDataLoaded;

                    // socket = getWebSocket();

                    socket.onmessage = (event) => {
                        const data = JSON.parse(event.data);

                        if (data.MessageType === "sender" || data.MessageType === "recepient") {
                            const { selectedHostId } = getState().ws;
                            if ((selectedHostId === 0 || selectedHostId !== data.HostId) && data.MessageType === "recepient") {
                                dispatch(setChatNotif(data));
                            }
                            updateCachedData((draft) => {
                                draft.push(data);
                            });
                        }
                    };

                    await cacheEntryRemoved;
                } catch (error) {
                    console.log("onCacheEntryAdded", error);
                }
            },
        }),
        sendChatMessage: builder.mutation({
            queryFn: async ({
                msg,
            }) => {
                try {
                    // socket = getWebSocket();

                    const jsonMsg = JSON.stringify(msg);
                    return new Promise((resolve) => {
                        socket.send(jsonMsg);
                        resolve({ data: jsonMsg });
                    });
                } catch (error) {
                    return { error };
                }
            },
        }),
        getChatHistory: builder.query({
            queryFn: async ({
                recepientId,
                page,
                pageSize,
            }) => {
                try {
                    const response = await API.get("WebSocketAPI", `/chatHistory/${recepientId}/${page}/${pageSize}`);
                    return {
                        data: response,
                    };
                } catch (error) {
                    return { error };
                }
            },
        }),
    }),
});

export const {
    useGetChatListQuery,
    useGetChatMessageQuery,
    useSendChatMessageMutation,
    useGetChatHistoryQuery,
    useLazyGetChatHistoryQuery,
} = wsAPI;
