/* eslint-disable no-extend-native */
/**
 * String.prototype.padStart() polyfill
 * https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padEnd
 */
if (!String.prototype.padEnd) {
    String.prototype.padEnd = function padEnd(targetLength, padString) {
        let tl = parseInt(targetLength, 10) || 0; // floor if number or convert non-number to 0;
        let ps = String((typeof padString !== "undefined" ? padString : " "));
        if (this.length > tl) {
            return String(this);
        }

        tl -= tl.length;
        if (tl > ps.length) {
            ps += ps.repeat(tl / ps.length); // append to original to ensure we are longer than needed
        }
        return String(this) + ps.slice(0, tl);
    };
}
