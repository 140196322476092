import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    styled,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import moment from "moment-timezone";
import "../../utils/moment-setup";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ServiceReportForm from "./ServiceReportForm";
import SendMessagetoHost from "./sendMessagetoHost";
import ServiceCancellationForm from "./serviceCancellationForm";
import ViewCompletionForm from "./ViewCompletionForm";
import ServiceDetailsRemarks from "./serviceDetailsRemarks";
import { useGetDetailsQuery, useUpdateServiceRequestStatusMutation } from "../../features/auth/spAPI";
import { getServiceStatusColorCode, SERVICE_STATUS_CONFIRMED_ACCEPTED, SERVICE_STATUS_DONE, SERVICE_STATUS_ONGOING, SERVICE_STATUS_PENDING_CONFIRMATION } from "../helper/ServiceStatusConstants";

// body > div.MuiDialog-root.MuiModal-root.css-zw3mfo-MuiModal-root-MuiDialog-root > div.MuiDialog-container.MuiDialog-scrollPaper.css-hz1bth-MuiDialog-container > div > div.MuiDialogContent-root.css-ypiqx9-MuiDialogContent-root > div > div > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.css-1vqhgp5-MuiGrid-root > span
const StyledGridDetails = styled(Grid)(({ theme }) => ({
    ".label": {
        display: "block",
        [theme.breakpoints.up("sm")]: {
            display: "inline",
        },
    },
    ".value": {
        display: "block",
        paddingLeft: theme.spacing(1),
        [theme.breakpoints.up("sm")]: {
            display: "inline",
        },
    },
}));

function ServiceDetails({
    OpenServiceDetailsModal,
    CloseServiceDetailsModal,
    PassServiceDetailsId }) {
    const theme = useTheme();
    const isFullscreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [ServiceDetailsId, setServiceDetailsId] = useState(null);
    const [ServiceDetailsInfo, setServiceDetailsInfo] = useState([]);
    const [StatusId, setStatusId] = useState(null);
    const [StatusName, setStatusName] = useState("");
    const [ProviderRemarks, setProviderRemarks] = useState("");
    const [OpenCancellationModal, setOpenCancellationForm] = useState(false);
    const [OpenMessageToHostModal, setOpenMessageToHostModal] = useState(false);
    const [OpenReportFormModal, setOpenReportFormModal] = useState(false);
    const [OpenCompletedForm, setOpenCompletedForm] = useState(false);
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 800,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };
    // get timezone from user's settings (or set to local timezone if empty)
    const userTimezone = useSelector((state) => state.auth.userInfo.timeZone) || moment.tz.guess();

    const { data: serviceGetDetails,
        error: errorGetDetails,
        status: statusGetDetails,
        isLoading: isLoadingGetDetails } = useGetDetailsQuery(
        {
            id: PassServiceDetailsId,
        },
        { skip: !OpenServiceDetailsModal },
    );

    const [updateServiceRequest,
        { isLoading: isLoadingUpdateServiceRequest,
            isError: isErrorUpdateServiceRequest,
            error: errorUpdateServiceRequest,
            isSuccess: isSuccessUpdateServiceRequest,
            reset: resetUpdateServiceRequest }] = useUpdateServiceRequestStatusMutation();

    useEffect(() => {
        if (serviceGetDetails !== null && serviceGetDetails !== undefined) {
            setServiceDetailsInfo(serviceGetDetails);
            setProviderRemarks(serviceGetDetails.providerRemarks);
            setStatusName(serviceGetDetails.serviceStatusName);
        }
    }, [serviceGetDetails]);

    const handleCloseCancelForm = (ChildCloseCancelForm) => {
        setOpenCancellationForm(ChildCloseCancelForm);
    };
    const handleCloseMessageForm = (ChildCloseMessageForm) => {
        setOpenMessageToHostModal(ChildCloseMessageForm);
    };

    const handleCloseReportForm = (ChildCloseReportForm) => {
        setOpenReportFormModal(ChildCloseReportForm);
    };

    const handleCloseCompletedForm = () => {
        setOpenCompletedForm(false);
    };
    const UpdateServiceStatusFunction = () => {
        updateServiceRequest({
            id: PassServiceDetailsId,
            serviceStatusId: StatusId,
            lastUpdatedDateTime: moment.utc(),
            providerRemarks: ProviderRemarks,
        });
    };

    const showLoader = isLoadingGetDetails || statusGetDetails === "pending"
        || isLoadingUpdateServiceRequest;

    useEffect(() => {
        if (isSuccessUpdateServiceRequest) {
            CloseServiceDetailsModal(false);
        }
    }, [isSuccessUpdateServiceRequest]);

    // if (serviceGetDetails === undefined) return "";
    return (
        <>
            <Dialog
                open={OpenServiceDetailsModal}
                maxWidth="md"
                fullWidth
                fullScreen={isFullscreen}
            >
                {/* <DialogTitle
                    sx={{
                        textAlign: "right",
                        paddingRight: 1,
                    }}
                >
                    {!showLoader && (
                        <IconButton
                            size="small"
                            onClick={() => { CloseServiceDetailsModal(false); }}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                </DialogTitle> */}
                {(!showLoader && ServiceDetailsInfo !== undefined) && (
                    <DialogTitle
                        sx={{
                            borderTop: `${theme.spacing(1.5)} solid ${getServiceStatusColorCode(serviceGetDetails === undefined ? 0 : serviceGetDetails.serviceStatusId)}`,
                        }}
                    >
                        {ServiceDetailsInfo.serviceStatusName}
                    </DialogTitle>
                )}
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        {ServiceDetailsInfo.id === PassServiceDetailsId && !showLoader && (
                            <Grid container spacing={1}>
                                <StyledGridDetails item xs={12}>
                                    <Typography
                                        variant="body2"
                                        component="span"
                                        color="text.secondary"
                                        className="label"
                                    >
                                        Property Address:
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className="value"
                                    >
                                        {`${ServiceDetailsInfo.propertyAddress} - `}
                                        <b>{ServiceDetailsInfo.hostName}</b>
                                    </Typography>
                                </StyledGridDetails>
                                <StyledGridDetails item xs={12}>
                                    <Typography
                                        variant="body2"
                                        component="span"
                                        color="text.secondary"
                                        className="label"
                                    >
                                        Request Status:
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className="value"
                                    >
                                        {`${ServiceDetailsInfo.serviceStatusName}`}
                                    </Typography>
                                </StyledGridDetails>
                                <StyledGridDetails item xs={12}>
                                    <Typography
                                        variant="body2"
                                        component="span"
                                        color="text.secondary"
                                        className="label"
                                    >
                                        Request Date:
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className="value"
                                    >
                                        {` ${moment.utc(ServiceDetailsInfo.requestDate).tz(userTimezone).format("LLLL zz")}`}
                                    </Typography>
                                </StyledGridDetails>
                                <Grid item xs={12}>
                                    <Card
                                        sx={{
                                            boxShadow: "none",
                                            borderWidth: 1,
                                            borderStyle: "solid",
                                            borderColor: theme.palette.grey[400],
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                variant="body2"
                                                component="span"
                                                color="text.secondary"
                                            >
                                                Remarks:
                                            </Typography>
                                            <Divider />
                                            <ServiceDetailsRemarks remarksString={ServiceDetailsInfo.providerRemarks} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                </DialogContent>
                {showLoader && (<LinearProgress />)}
                <DialogActions>
                    <Button
                        disabled={showLoader}
                        onClick={() => CloseServiceDetailsModal(false)}
                        sx={{
                            display: showLoader && "none",
                            height: "100%",
                        }}
                        variant="contained"
                        color="close"
                        size="small"
                    >
                        Close
                    </Button>
                    {ServiceDetailsInfo.serviceStatusId === SERVICE_STATUS_PENDING_CONFIRMATION && ServiceDetailsInfo.id === PassServiceDetailsId
                        && moment.utc() <= moment.utc(ServiceDetailsInfo.requestDate) && (
                        <>
                            <Button
                                disabled={showLoader}
                                onClick={() => {
                                    setServiceDetailsId(ServiceDetailsInfo.id);
                                    setStatusId(ServiceDetailsInfo.serviceStatusId);
                                    setStatusName(ServiceDetailsInfo.serviceStatusName);
                                    setProviderRemarks(ServiceDetailsInfo.providerRemarks);
                                    setOpenMessageToHostModal(true);
                                }}
                                variant="contained"
                                size="small"
                                sx={{
                                    height: "100%",
                                }}
                            >
                                Send Message to Host
                            </Button>
                            <Button
                                disabled={showLoader}
                                onClickCapture={() => {
                                    setStatusId(ServiceDetailsInfo.serviceStatusId + 1);
                                    setProviderRemarks(ServiceDetailsInfo.providerRemarks);
                                }}
                                onClick={UpdateServiceStatusFunction}
                                variant="contained"
                                color="success"
                                size="small"
                                sx={{
                                    height: "100%",
                                }}
                            >
                                Accept
                            </Button>
                            <Button
                                disabled={showLoader}
                                onClick={() => {
                                    setServiceDetailsId(ServiceDetailsInfo.id);
                                    setStatusId(ServiceDetailsInfo.serviceStatusId);
                                    setStatusName(ServiceDetailsInfo.serviceStatusName);
                                    setProviderRemarks(ServiceDetailsInfo.providerRemarks);
                                    setOpenCancellationForm(true);
                                }}
                                variant="contained"
                                color="error"
                                size="small"
                                sx={{
                                    height: "100%",
                                }}
                            >
                                Reject
                            </Button>
                        </>
                    )}
                    {ServiceDetailsInfo.serviceStatusId === SERVICE_STATUS_PENDING_CONFIRMATION && ServiceDetailsInfo.id === PassServiceDetailsId
                        && moment.utc() > moment.utc(ServiceDetailsInfo.requestDate)
                        && (
                            <>
                                <Button
                                    disabled
                                    variant="contained"
                                    size="small"
                                >
                                    Send Message to Host
                                </Button>
                                <Button
                                    disabled
                                    variant="contained"
                                    color="success"
                                    size="small"
                                    sx={{
                                        height: "100%",
                                    }}
                                >
                                    Accept
                                </Button>
                                <Button
                                    disabled
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    sx={{
                                        height: "100%",
                                    }}
                                >
                                    Reject
                                </Button>
                            </>
                        )}

                    {ServiceDetailsInfo.serviceStatusId === SERVICE_STATUS_CONFIRMED_ACCEPTED && ServiceDetailsInfo.id === PassServiceDetailsId
                        && true // moment.utc() >= moment.utc(ServiceDetailsInfo.requestDate)
                        && (
                            <>
                                <Button
                                    disabled={showLoader}
                                    onClick={() => {
                                        setServiceDetailsId(ServiceDetailsInfo.id);
                                        setStatusId(ServiceDetailsInfo.serviceStatusId);
                                        setStatusName(ServiceDetailsInfo.serviceStatusName);
                                        setProviderRemarks(ServiceDetailsInfo.providerRemarks);
                                        setOpenMessageToHostModal(true);
                                    }}
                                    variant="contained"
                                    size="small"
                                    sx={{
                                        height: "100%",
                                    }}
                                >
                                    Send Message to Host
                                </Button>
                                <Button
                                    disabled={showLoader}
                                    onClickCapture={() => {
                                        setStatusId(ServiceDetailsInfo.serviceStatusId + 1);
                                        setProviderRemarks(ServiceDetailsInfo.providerRemarks);
                                    }}
                                    onClick={UpdateServiceStatusFunction}
                                    variant="contained"
                                    color="success"
                                    size="small"
                                    sx={{
                                        height: "100%",
                                    }}
                                >
                                    Start Service
                                </Button>
                                <Button
                                    disabled={showLoader}
                                    onClick={() => {
                                        setServiceDetailsId(ServiceDetailsInfo.id);
                                        setStatusId(ServiceDetailsInfo.serviceStatusId);
                                        setStatusName(ServiceDetailsInfo.serviceStatusName);
                                        setProviderRemarks(ServiceDetailsInfo.providerRemarks);
                                        setOpenCancellationForm(true);
                                    }}
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    sx={{
                                        height: "100%",
                                    }}
                                >
                                    Cancel Service
                                </Button>
                            </>
                        )}
                    {ServiceDetailsInfo.serviceStatusId === SERVICE_STATUS_CONFIRMED_ACCEPTED && ServiceDetailsInfo.id === PassServiceDetailsId
                        && false // moment.utc() < moment.utc(ServiceDetailsInfo.requestDate)
                        && (
                            <>
                                <Button
                                    variant="contained"
                                    size="small"
                                    disabled
                                    sx={{
                                        height: "100%",
                                    }}
                                >
                                    Send Message to Host
                                </Button>
                                <Button
                                    variant="contained"
                                    color="success"
                                    size="small"
                                    disabled
                                    sx={{
                                        height: "100%",
                                    }}
                                >
                                    Start Service
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    disabled
                                    sx={{
                                        height: "100%",
                                    }}
                                >
                                    Cancel Service
                                </Button>
                            </>
                        )}

                    {ServiceDetailsInfo.serviceStatusId === SERVICE_STATUS_ONGOING && ServiceDetailsInfo.id === PassServiceDetailsId && (
                        <>
                            <Button
                                onClick={() => {
                                    setServiceDetailsId(ServiceDetailsInfo.id);
                                    setStatusId(ServiceDetailsInfo.serviceStatusId);
                                    setStatusName(ServiceDetailsInfo.serviceStatusName);
                                    setProviderRemarks(ServiceDetailsInfo.providerRemarks);
                                    setOpenMessageToHostModal(true);
                                }}
                                variant="contained"
                                size="small"
                                disabled={showLoader}
                                sx={{
                                    height: "100%",
                                }}
                            >
                                Send Message to Host
                            </Button>
                            <Button
                                onClick={() => {
                                    setOpenReportFormModal(true);
                                    setServiceDetailsId(ServiceDetailsInfo.id);
                                    setStatusName(ServiceDetailsInfo.serviceStatusName);
                                    setProviderRemarks(ServiceDetailsInfo.providerRemarks);
                                }}
                                variant="contained"
                                color="success"
                                size="small"
                                disabled={showLoader}
                                sx={{
                                    height: "100%",
                                }}
                            >
                                Done
                            </Button>
                            <Button
                                onClick={() => {
                                    setServiceDetailsId(ServiceDetailsInfo.id);
                                    setStatusId(ServiceDetailsInfo.serviceStatusId);
                                    setStatusName(ServiceDetailsInfo.serviceStatusName);
                                    setProviderRemarks(ServiceDetailsInfo.providerRemarks);
                                    setOpenCancellationForm(true);
                                }}
                                variant="contained"
                                color="error"
                                size="small"
                                disabled={showLoader}
                                sx={{
                                    height: "100%",
                                }}
                            >
                                Cancel Service
                            </Button>
                        </>
                    )}

                    {ServiceDetailsInfo.serviceStatusId === SERVICE_STATUS_DONE && ServiceDetailsInfo.id === PassServiceDetailsId && (
                        <Button
                            onClick={() => {
                                setOpenCompletedForm(true);
                                setServiceDetailsId(ServiceDetailsInfo.id);
                            }}
                            variant="contained"
                            disabled={showLoader}
                            sx={{
                                height: "100%",
                            }}
                        >
                            View Completion Report
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <ServiceReportForm
                OpenReportFormModal={OpenReportFormModal}
                CloseReportFormModal={handleCloseReportForm}
                CloseServiceDetailsForm={CloseServiceDetailsModal}
                PassServiceDetailsId={PassServiceDetailsId}
                PassStatusId={StatusId}
                PassStatusName={StatusName}
                PassSPRemarks={ProviderRemarks}
            />
            <ViewCompletionForm
                OpenCompletedDialog={OpenCompletedForm}
                CloseCompletedDialog={handleCloseCompletedForm}
                PassServiceDetailsId={PassServiceDetailsId}
            />
            <ServiceCancellationForm
                OpenCancellationForm={OpenCancellationModal}
                CloseCancellationForm={handleCloseCancelForm}
                CloseServiceDetailsForm={CloseServiceDetailsModal}
                PassFormStyle={style}
                PassIdFromDetails={ServiceDetailsId}
                PassStatusId={StatusId}
                PassStatusName={StatusName}
                PassSPRemarks={ProviderRemarks}
            />
            <SendMessagetoHost
                OpenMessageToHost={OpenMessageToHostModal}
                CloseMessageToHost={handleCloseMessageForm}
                CloseServiceDetailsForm={CloseServiceDetailsModal}
                PassFormStyle={style}
                PassIdFromDetails={ServiceDetailsId}
                PassStatusId={StatusId}
                PassStatusName={StatusName}
                PassSPRemarks={ProviderRemarks}
            />
        </>
    );
}

export default ServiceDetails;
