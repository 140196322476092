export const STATUS_CODE_OTP_SUCCESS = 0;
export const STATUS_CODE_PROFILE_COMPLETE = 1;

// export async function GetProfileAndSignUpStatus() {
//     const userProfilePromise = store.dispatch(spAPI.endpoints.getUserProfile.initiate());
//     const { data: dataUserProfile } = await userProfilePromise;

//     // const resp = await API.get("ProviderApi", "/getProfile");

//     switch (dataUserProfile.signUpStatusCode) {
//         case STATUS_CODE_OTP_SUCCESS:
//             // redirect to complete registration
//             return {
//                 user: dataUserProfile,
//                 redirectTo: "/complete-registration",
//             };
//         case STATUS_CODE_PROFILE_COMPLETE:
//             // redirect to dashboard
//             return {
//                 user: dataUserProfile,
//                 redirectTo: "/dashboard",
//             };
//         default:
//             return {
//                 user: "",
//                 redirectTo: "",
//             };
//     }
// }
