import { Box, Grid, Alert, TextField, Button, Stack, Typography, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { API, Auth } from "aws-amplify";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { AccountCircle } from "@mui/icons-material";
import { setAuthenticationStatus, setLogInProcess, showLoader } from "../features/auth/authSlice";
import VerifyOtp from "./VerifyOtp";
import CustomPasswordField from "./shared/custom_password";
import { LOGIN_FORCE_PASSWORD_CHANGE, LOGIN_STEP, LOGIN_VERIFYOTP_STEP } from "../utils/enum";
import ChangePassword from "./ChangePassword";
import HouseCard from "./helper/HouseCard";

// retries an async function f() n times, with d milliseconds of delay between calls
// returns the return value of the first successful f() call
// otherwise throws the error of the last f() call
async function retryAsyncFunction(f, n, d) {
    for (let i = 0; i < n; i += 1) {
        try {
            // eslint-disable-next-line no-await-in-loop
            const returnVal = await f();
            return returnVal;
        } catch (err) {
            if (i === n - 1) throw err;
        }
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve) => {
            setTimeout(resolve, d);
        });
    }
    return null;
}

// Amplify API.get() doesn't work without auth code so we'll have to use vanilla js fetch()
async function getPhoneNumber(inviteCode) {
    const endpoint = await API.endpoint("ProviderPublicApi");
    const url = `${endpoint}/getPhoneNumberFromInviteCode?inviteCode=${inviteCode}`;
    const res = await fetch(url, { headers: { "Access-Control-Request-Method": "GET" } });
    const text = await res.text();
    if (res.ok) {
        return text; // text is phone number
    }
    throw new Error(text); // text is error message
}

export default function Invite() {
    const isInitialLoadFinished = useSelector((state) => state.auth.isInitialLoadFinished);
    const currentLogInProcess = useSelector((state) => state.auth.logInProcess);
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const [session, setSession] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [phoneLogin, setPhoneLogin] = useState("");
    const [tempPassword, setTempPassword] = useState("");
    const [currentUser, setCurrentUser] = useState(null);
    const [invalidCode, setInvalidCode] = useState(false);

    useEffect(() => {
        dispatch(setLogInProcess(LOGIN_STEP));
    }, [dispatch]);

    useEffect(() => {
        (async () => {
            if (!isInitialLoadFinished) return;
            const inviteCode = searchParams.get("code");
            if (!inviteCode) return;

            dispatch(showLoader({ open: true, message: "Verifying, please wait..." }));
            try {
                const phoneNumber = await retryAsyncFunction(async () => getPhoneNumber(inviteCode), 3, 10000);
                const phoneUser = phoneNumber.replace("+", "");
                setPhoneLogin(phoneUser);
            } catch (err) {
                setErrorMessage("This page has encountered an error. Please try again later.");
            } finally {
                dispatch(showLoader({ open: false, message: "" }));
            }
        })();
    }, [dispatch, searchParams, isInitialLoadFinished]);

    const handleLogin = async (user, tempPass) => {
        try {
            dispatch(showLoader({ open: true, message: "Please wait..." }));
            setErrorMessage("");
            const loginData = await Auth.signIn(user, tempPass);
            if (loginData.challengeName === "NEW_PASSWORD_REQUIRED") {
                dispatch(setLogInProcess(LOGIN_FORCE_PASSWORD_CHANGE));
            }
            setCurrentUser(loginData);
        } catch (error) {
            if (error.name === "NotAuthorizedException") {
                setErrorMessage("Invalid temporary password.");
            } else {
                setErrorMessage("An error occured while trying to loging in. Please try again later.");
            }
        } finally {
            dispatch(showLoader({ open: false, message: "" }));
        }
    };

    return (
        <>
            {currentLogInProcess === LOGIN_STEP && (
                <HouseCard
                    title="Verify Temporary Password"
                >
                    <Grid container spacing={2}>
                        {errorMessage && (
                            <Grid item xs={12}>
                                <Alert severity="error">{errorMessage}</Alert>
                            </Grid>
                        )}
                        {phoneLogin.length > 0 && (
                            <>
                                {/* {invalidCode} */}
                                <Grid item xs={12}>
                                    <TextField
                                        value={phoneLogin}
                                        label="Phone"
                                        disabled
                                        size="small"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AccountCircle />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomPasswordField
                                        size="small"
                                        label="Temporary Password"
                                        onChange={(e) => {
                                            setTempPassword(e.target.value);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key !== "Enter") return;
                                            handleLogin(phoneLogin, tempPassword);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={() => handleLogin(phoneLogin, tempPassword)}
                                        disabled={tempPassword.length === 0}
                                    >
                                        Login
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </HouseCard>
            )}
            {currentLogInProcess === LOGIN_FORCE_PASSWORD_CHANGE && (
                <ChangePassword
                    currentUser={currentUser}
                />
            )}
        </>
    );
}
