import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fetchServiceRequests: false,
};

export const fetchDataSlice = createSlice({
    name: "fetchData",
    initialState,
    reducers: {
        fetchServiceRequestList: (state, action) => {
            state.fetchServiceRequests = action.payload;
        },
    },
});

export const {
    fetchServiceRequestList,
} = fetchDataSlice.actions;

export default fetchDataSlice.reducer;
