import { Alert, Button, Grid, TextField, Typography, useTheme } from "@mui/material";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";
import { showLoader } from "../features/auth/authSlice";
import PhoneInput from "./helper/react-phone-input-2";
import CustomPasswordField from "./shared/custom_password";
import HouseCard from "./helper/HouseCard";
import CustomPhoneInput from "./shared/custom_phoneinput";

export default function ForgotPassword() {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [phone, setPhone] = useState("");
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [formError, setFormError] = useState({
        show: false,
        message: "",
    });
    const [passwordIsValid, setIsPasswordValid] = useState(false);
    const [formIsComplete, setFormIsComplete] = useState(false);

    useEffect(() => {
        // password validation
        if (password !== confirmPassword) setIsPasswordValid(false);
        else setIsPasswordValid(true);

        // form validation
        if (code.length !== 0
            && password.length !== 0
            && confirmPassword.length !== 0
            && (password === confirmPassword)) setFormIsComplete(true);
        else setFormIsComplete(false);
    }, [code, password, confirmPassword]);

    const handleResetPassword = async (username) => {
        setFormError((prev) => ({
            ...prev,
            show: false,
            message: "",
        }));
        try {
            dispatch(showLoader({ open: true, message: "Sending code..." }));
            const output = await Auth.forgotPassword(username);
            setShowResetPassword(true);
        } catch (err) {
            setFormError((prev) => ({
                ...prev,
                show: true,
                message: err.message,
            }));
        } finally {
            dispatch(showLoader({ open: false, message: "" }));
        }
    };

    const handleConfirmedResetPassword = async ({
        username, confirmationCode, newPassword,
    }) => {
        try {
            dispatch(showLoader({ open: true, message: "Please wait..." }));

            await Auth.forgotPasswordSubmit(username, confirmationCode, newPassword);
            const loginResult = await Auth.signIn(username, newPassword);
            window.location.replace("/dashboard");
        } catch (error) {
            setFormError((prev) => ({
                ...prev,
                show: true,
                message: error.message,
            }));
        } finally {
            dispatch(showLoader({ open: false, message: "" }));
        }
    };

    const RequestResetPasswordComponent = (
        <Grid
            container
            spacing={2}
        >
            <Grid item xs={12}>
                <CustomPhoneInput
                    label="Phone"
                    fullWidth
                    onChange={(e) => {
                        setPhone(e);
                        setFormError((prev) => ({
                            ...prev,
                            show: false,
                            message: "",
                        }));
                    }}
                    onKeyPress={(e) => {
                        if (phone.length === 0) return;
                        handleResetPassword(phone);
                    }}
                />
                {/* <PhoneInput
                    country="us"
                    value={phone}
                    onChange={(e) => setPhone(e)}
                    size="small"
                    sx={{ flex: 1 }}
                    dropdownStyle={{ zIndex: 100 }}
                    disableSearch
                    inputStyle={{
                        width: "100%",
                    }}
                    fullWidth
                    onEnterKeyPress={(e) => {
                        if (phone.length === 0) return;
                        handleResetPassword(phone);
                    }}
                    inputProps={{
                        autoFocus: true,
                    }}
                /> */}
            </Grid>
            <Grid item xs={12}>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={() => handleResetPassword(phone)}
                >
                    Send Code
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Button
                    fullWidth
                    variant="text"
                    onClick={() => navigate("/login")}
                >
                    Back to Login
                </Button>
            </Grid>
        </Grid>
    );

    const ResetPasswordComponent = (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <MuiOtpInput
                    value={code}
                    inputMode="numeric"
                    length={6}
                    onChange={(newValue) => setCode(newValue)}
                    TextFieldsProps={{
                        size: "small",
                    }}
                    onComplete={() => console.log("complete")}
                    onKeyDown={(e) => {
                        if (e.key !== "Enter") return;
                        if (code.length !== 6) return;
                        handleConfirmedResetPassword({
                            username: phone,
                            confirmationCode: code,
                            newPassword: password,
                        });
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <CustomPasswordField
                    fullWidth
                    size="small"
                    label="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Typography
                    component="div"
                    variant="caption"
                    sx={{ marginTop: theme.spacing(1) }}
                    color="primary"
                >
                    Your password must contain both lowercase and uppercase letters, and at least one number.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <CustomPasswordField
                    fullWidth
                    size="small"
                    label="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key !== "Enter") return;
                        handleConfirmedResetPassword({
                            username: phone,
                            confirmationCode: code,
                            newPassword: password,
                        });
                    }}
                />
                <Typography
                    variant="caption"
                    color="error"
                >
                    {!passwordIsValid && "Both password should be equal."}
                    &nbsp;
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                        handleConfirmedResetPassword({
                            username: phone,
                            confirmationCode: code,
                            newPassword: password,
                        });
                    }}
                    disabled={!formIsComplete}
                >
                    Submit
                </Button>
            </Grid>
        </Grid>
    );
    return (
        <HouseCard
            title="Forgot Password"
        >
            <Grid
                container
                spacing={2}
            >
                {formError.show && (
                    <Grid item xs={12}>
                        <Alert
                            severity="error"
                            onClose={() => setFormError((prev) => ({
                                ...prev,
                                show: false,
                                message: "",
                            }))}
                        >
                            {formError.message}
                        </Alert>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {!showResetPassword ? RequestResetPasswordComponent : ResetPasswordComponent}
                </Grid>
            </Grid>
        </HouseCard>
    );
}
