import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    // determins whether user clicks on sign up or log in link (values: SIGNUP/LOGIN/'')
    authenticationType: "",
    // determins whether the user is successfully signed or logged in
    isAuthenticated: false,
    // use for sign-up step process (Sign Up > Verify OTP > Update User Profile)
    signUpProcess: "",
    // use for log-in step process (Log In > Verify OTP)
    logInProcess: "",
    // user info (cognito and db)
    userInfo: {
        firstName: "",
        lastName: "",
        locationLong: "",
        locationLat: "",
        address: "",
        timeZone: "",
        exclusivity: 1,
        servicesOffered: [],
    },
    signUpProcessFlow: {
        statusCode: 0,
    },
    loader: {
        open: false,
        message: "",
    },
    isInitialLoadFinished: false,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        // use to set whether user clicks on sign up or log in page (values: SIGNUP/LOGIN/'')
        setAuthenticationType: (state, action) => {
            state.authenticationType = action.payload;
        },
        // use to set if user already logged in or not
        setAuthenticationStatus: (state, action) => {
            state.isAuthenticated = action.payload;
        },
        // use for sign-up step process
        setSignUpProcess: (state, action) => {
            state.signUpProcess = action.payload;
            state.logInProcess = "";
            state.authenticationType = "SIGNUP";
        },
        // use for log-in step process
        setLogInProcess: (state, action) => {
            state.signUpProcess = "";
            state.logInProcess = action.payload;
            state.authenticationType = "LOGIN";
        },
        // store user info
        setUserInfo: (state, action) => {
            state.userInfo.firstName = action.payload.firstName;
            state.userInfo.lastName = action.payload.lastName;
            state.userInfo.address = action.payload.address;
            state.userInfo.locationLat = action.payload.locationLat;
            state.userInfo.locationLong = action.payload.locationLong;
            state.userInfo.timeZone = action.payload.timeZone;
            state.userInfo.servicesOffered = action.payload.servicesOffered;
            state.userInfo.exclusivity = action.payload.exclusivity;
            state.signUpProcessFlow.statusCode = action.payload.signUpStatusCode;
        },
        // updateSignUpProcessFlow: (state, action) => {
        //     state.signUpProcessFlow.statusCode = action.payload.statusCode;
        //     if (action.payload.initialPropertyId) state.signUpProcessFlow.initialPropertyId = action.payload.initialPropertyId;
        //     if (action.payload.initialPropertyTimezone) state.signUpProcessFlow.initialPropertyTimezone = action.payload.initialPropertyTimezone;

        //     const checkSignUpStatus = (action.payload.statusCode !== STATUS_CODE_OTP_SUCCESS
        //         && action.payload.statusCode !== STATUS_CODE_PROFILE_COMPLETE
        //         && action.payload.statusCode !== STATUS_CODE_ADD_PROPERTY_COMPLETE
        //         && action.payload.statusCode !== STATUS_CODE_ADD_CALENDAR_COMPLETE
        //         && action.payload.statusCode !== STATUS_CODE_ADD_DEVICE_FAILURE);

        //     if (checkSignUpStatus) {
        //         state.signUpProcessFlow.isSignUpStatusComplete = true;
        //     } else {
        //         state.signUpProcessFlow.isSignUpStatusComplete = false;
        //     }
        // },
        // logout: clear authentication state
        clear: (state) => {
            state.isAuthenticated = false;
            state.authenticationType = "";
            state.signUpProcess = "";
            state.logInProcess = "";
        },
        showLoader: (state, action) => {
            state.loader.message = action.payload.message;
            state.loader.open = action.payload.open;
        },

        finishInitialLoad: (state) => {
            state.isInitialLoadFinished = true;
        },
    },
});

export const { verifyOtp,
    setAuthenticationStatus,
    setSignUpProcess,
    setLogInProcess,
    setAuthenticationType,
    setUserInfo,
    updateSignUpProcessFlow,
    clear,
    showLoader,
    finishInitialLoad } = authSlice.actions;

export default authSlice.reducer;
