import SendIcon from "@mui/icons-material/Send";
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, styled, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetCompletedServiceQuery, useUpdateServiceRequestStatusMutation } from "../../features/auth/spAPI";
import TempReportForm from "./tempReportForm";

const StyledGridDetails = styled(Grid)(({ theme }) => ({
    ".label": {
        display: "block",
        [theme.breakpoints.up("sm")]: {
            display: "inline",
        },
    },
    ".value": {
        display: "block",
        paddingLeft: theme.spacing(1),
        [theme.breakpoints.up("sm")]: {
            display: "inline",
        },
    },
}));

function ServiceReportForm(
    {
        OpenReportFormModal,
        CloseReportFormModal,
        CloseServiceDetailsForm,
        PassServiceDetailsId,
        PassStatusName,
        PassSPRemarks,
    },
) {
    // get timezone from user's settings (or set to local timezone if empty)
    const userTimezone = useSelector((state) => state.auth.userInfo.timeZone) || moment.tz.guess();

    const [isLoading, setIsLoading] = useState(false);
    const [MessageTxt, setMessageTxt] = useState("n/a");
    const [getCompletedDetails, setGetCompletedDetails] = useState(null);
    const [localStartTime, setLocalStartTime] = useState(null);
    const dispatch = useDispatch();
    const fetchList = useSelector((state) => state.fetchData.fetchServiceRequests);
    const theme = useTheme();
    const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const { data: dataCommpletedService,
        error: errorCompletedService,
        status: statusCompletedService,
        isLoading: isLoadingCompletedService } = useGetCompletedServiceQuery(
        { id: PassServiceDetailsId },
        { skip: !OpenReportFormModal },
    );

    const [updateServiceRequest,
        { isLoading: isLoadingUpdateServiceRequest,
            isError: isErrorUpdateServiceRequest,
            error: errorUpdateServiceRequest,
            isSuccess: isSuccessUpdateServiceRequest,
            reset: resetUpdateServiceRequest }] = useUpdateServiceRequestStatusMutation();

    useEffect(() => {
        if (dataCommpletedService !== null && dataCommpletedService !== undefined) {
            setGetCompletedDetails(dataCommpletedService);
            const respStartDateTime = new Date(dataCommpletedService.startDateTime);
            const locale = window.navigator.userLanguage || window.navigator.language;

            setLocalStartTime(respStartDateTime.toLocaleString(locale, {
                hour: "numeric",
                minute: "numeric",
            }).toUpperCase());
        }
    }, [dataCommpletedService]);

    useEffect(() => {
        if (isSuccessUpdateServiceRequest) {
            CloseReportFormModal(false);
            CloseServiceDetailsForm(false);
        }
    }, [isSuccessUpdateServiceRequest]);

    const showLoader = isLoadingUpdateServiceRequest || isLoadingCompletedService || isLoading;

    const UpdateMessageFunction = () => {
        updateServiceRequest({
            id: PassServiceDetailsId,
            serviceStatusId: 3,
            lastUpdatedDateTime: moment.utc(),
            providerRemarks: `${PassSPRemarks}${`${moment().tz(userTimezone).format()}`},${PassStatusName},Completed,${JSON.stringify(MessageTxt)}\n`,
        });
    };

    return (
        <Dialog
            open={OpenReportFormModal}
            maxWidth="md"
            fullWidth
            fullScreen={isFullScreen}
        >
            <DialogTitle sx={{ verticalAlign: "center" }}>
                Service Completion Form
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    sx={{ mt: theme.spacing(1) }}
                >
                    <Grid item xs={12}>
                        {getCompletedDetails !== null && (
                            <Grid
                                container
                            >
                                <Grid item xs={12} md={6}>
                                    <Typography
                                        variant="body1"
                                        fontWeight={theme.typography.fontWeightBold}
                                    >
                                        {`${getCompletedDetails.propertyName} - ${getCompletedDetails.hostName}`}
                                    </Typography>
                                </Grid>
                                <StyledGridDetails item xs={12} md={6}>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        component="span"
                                        className="label"
                                    >
                                        Term Service Date:
                                        {" "}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        component="span"
                                        className="value"
                                    >
                                        {`${moment(getCompletedDetails.requestDate).tz(userTimezone).format("MM-DD-YYYY")}`}
                                    </Typography>
                                </StyledGridDetails>
                                <StyledGridDetails item xs={12} md={6}>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        component="span"
                                        className="label"
                                    >
                                        Address
                                        {" "}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        component="span"
                                        className="value"
                                    >
                                        {getCompletedDetails.propertyAddress}
                                    </Typography>
                                </StyledGridDetails>
                                <StyledGridDetails item xs={12} md={6}>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        component="span"
                                        className="label"
                                    >
                                        Started at
                                        {" "}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        component="span"
                                        className="value"
                                    >
                                        {`${localStartTime !== null ? localStartTime : ""}
                                            (${moment.tz(userTimezone).zoneName()} ${moment.tz(userTimezone).format("Z")}  ${userTimezone})`}
                                    </Typography>
                                </StyledGridDetails>
                                <Grid item xs={12}>
                                    <Card
                                        sx={{
                                            boxShadow: "none",
                                            borderWidth: 1,
                                            borderColor: theme.palette.grey[400],
                                            borderStyle: "solid",
                                        }}
                                    >
                                        <CardContent>
                                            <TempReportForm
                                                RoomsToUpload={getCompletedDetails.propertyRooms}
                                                ServiceRequestId={PassServiceDetailsId}
                                                ServiceReportFormLoadingSetter={setIsLoading}
                                            />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            minRows={3}
                            type="textarea"
                            multiline
                            variant="outlined"
                            label="Remarks"
                            fullWidth
                            value={MessageTxt}
                            onChange={(e) => {
                                setMessageTxt(e.target.value);
                            }}
                        />
                    </Grid>
                </Grid>

            </DialogContent>
            {showLoader && (<LinearProgress />)}
            <DialogActions>
                <Button
                    variant="contained"
                    color="close"
                    size="small"
                    onClick={() => { CloseReportFormModal(false); }}
                    disabled={showLoader}
                >
                    Close
                </Button>
                <Button
                    variant="contained"
                    onClick={UpdateMessageFunction}
                    disabled={showLoader}
                >
                    Send Report to Host &nbsp;
                    <SendIcon sx={{ height: "1rem" }} />
                </Button>
            </DialogActions>
        </Dialog>

    // <Modal
    //     open={OpenReportFormModal}
    //     onClose={() => { CloseReportFormModal(false); }}
    //     aria-labelledby="modal-modal-title"
    //     aria-describedby="modal-modal-description"
    // >
    //     <Box sx={PassFormStyle}>
    //         <h3>Service Completion Form</h3>
    //         <Grid container display="flex" minWidth={800} getCompletedDetailsSpacing={1} spacing={1} border={0}>
    //             <Grid item xs={6}><Typography>{`${PassPropertyName} - ${PassHostName}`}</Typography></Grid>
    //             <Grid item xs={4}><Typography>Term Service Date: 06-08-2023</Typography></Grid>
    //             <Grid item xs={6}><Typography>{`Address: ${PassAddress}`}</Typography></Grid>
    //             <Grid item xs={4}><Typography>start time - end time timezone </Typography></Grid>
    //             <Grid item xs={10}>
    //                 <Card sx={{ border: "dotted", display: "flex", justifyContent: "center", color: "grey", width: "100%", height: "30rem" }}>
    //                     <CardContent>
    //                         <TempReportForm />
    //                     </CardContent>
    //                 </Card>
    //             </Grid>
    //             <Grid item xs={10}><TextField mingetCompletedDetailss={3} type="textarea" multiline variant="outlined" label="Remarks" fullWidth /></Grid>
    //             <Grid item xs={10}>
    //                 <Button variant="contained" fullWidth>
    //                     Send Report to Host &nbsp;
    //                     <SendIcon sx={{ height: "1rem" }} />
    //                 </Button>
    //             </Grid>
    //         </Grid>
    //     </Box>
    // </Modal>
    );
}

export default ServiceReportForm;
