import { Amplify, Auth } from "aws-amplify";
import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import AmazonConfig from "./amazonConfig";
import Dashboard from "./component/Dashboard";
import ForgotPassword from "./component/ForgotPassword";
import Invite from "./component/Invite";
import Layout from "./component/Layout";
import Login from "./component/Login";
import Profile from "./component/Profile";
import RequiredInfo from "./component/RequiredInfo";
import ServiceReportForm from "./component/services/ServiceReportForm";
import SignUp from "./component/SignUp";
import TestMapComponent from "./component/TestMapComponent";
import { spAPI } from "./features/auth/spAPI";
import store from "./store";
import { ProtectedRoute } from "./utils/ProtectedRoute";
import MessengerPage from "./component/websocket/MessengerPage";

const checkAuth = async () => {
    Amplify.configure(await AmazonConfig());
    const auth = await Auth.currentAuthenticatedUser({ bypassCache: true })
        .then((r) => r)
        .catch(() => null);

    if (auth === null) return null;
    const userProfilePromise = store.dispatch(spAPI.endpoints.getUserProfile.initiate());
    const { data: dataUserProfile } = await userProfilePromise;
    // const user = await API.get("ProviderApi", "/getProfile");

    return {
        auth,
    };
};

const AppRoute = createBrowserRouter(
    createRoutesFromElements(
        <Route
            element={<Layout />}
            loader={checkAuth}
        >
            <Route element={<Login />} path="/" exact />
            <Route element={<ProtectedRoute />}>
                <Route element={<Dashboard />} path="/dashboard" />
                <Route element={<RequiredInfo />} path="/complete-registration" />
                <Route path="profile">
                    <Route path="" element={<Profile />} />
                </Route>
                <Route element={<ServiceReportForm />} path="/report" />
                <Route element={<MessengerPage />} path="/messenger" />
            </Route>
            <Route element={<TestMapComponent />} path="/testmap" />
            <Route element={<Login />} path="/login" />
            <Route element={<ForgotPassword />} path="/forgot-password" />
            <Route element={<SignUp />} path="/signup" />
            <Route element={<Invite />} path="/invite" />
        </Route>,
    ),
);

export default AppRoute;
