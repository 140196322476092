import { HomeRepairService } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
    Alert,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Skeleton,
    Snackbar,
    styled,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetPendingJobsQuery, useUpdateServiceRequestStatusMutation } from "../../features/auth/spAPI";
import EmptyState from "../helper/EmptyState";
import ServiceCancellationForm from "./serviceCancellationForm";
import { showLoader } from "../../features/auth/authSlice";

const StyledGridDetails = styled(Grid)(({ theme }) => ({
    ".label": {
        display: "block",
        // [theme.breakpoints.up("sm")]: {
        //     display: "inline",
        // },
    },
    ".value": {
        display: "block",
        paddingLeft: theme.spacing(1),
        // [theme.breakpoints.up("sm")]: {
        //     display: "inline",
        // },
    },
}));

const StyledGridActions = styled(Grid)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    button: {
        width: "46%",
        // [theme.breakpoints.up("md")]: {
        //     width: "100%",
        // },
    },
}));
function Pendingjobs() {
    const dispatch = useDispatch();
    const dateNowinUTC = moment.utc().format();
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [ServiceDetailsId, setServiceDetailsId] = useState(null);
    const [StatusName, setStatusName] = useState("Pending Confirmation");
    const [ProviderRemarks, setProviderRemarks] = useState("");
    const [OpenCancellationModal, setOpenCancellationForm] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 800,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    const {
        data: dataPendingJobs,
        isLoading: isLoadingPendingJobs,
        isFetching: isFetchingPendingJobs,
        isSuccess: isSuccessPendingJobs,
        isError: isErrorPendingJobs } = useGetPendingJobsQuery();

    const [updateServiceRequest,
        { isLoading: isLoadingUpdateServiceRequest,
            isFetching: isFetchingUpdateServiceRequest,
            isError: isErrorUpdateServiceRequest,
            error: errorUpdateServiceRequest,
            isSuccess: isSuccessUpdateServiceRequest,
            reset: resetUpdateServiceRequest }] = useUpdateServiceRequestStatusMutation();

    useEffect(() => {
        dispatch(showLoader({
            open: isLoadingUpdateServiceRequest || isFetchingUpdateServiceRequest,
            message: "Please wait...",
        }));
    }, [isLoadingUpdateServiceRequest, isFetchingUpdateServiceRequest]);

    const handleCloseCancelForm = () => {
        setOpenCancellationForm(false);
    };

    const handleClosealert = () => {
        setOpenErrorAlert(false);
    };

    const action = (
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClosealert}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    );

    const isGettingData = isLoadingPendingJobs || isFetchingPendingJobs;

    // get timezone from user's settings (or set to local timezone if empty)
    const userTimezone = useSelector((state) => state.auth.userInfo.timeZone) || moment.tz.guess();

    return (
        <>
            <Snackbar
                open={openErrorAlert}
                onClose={() => { setOpenErrorAlert(true); }}
                action={action}
            >
                <Alert severity="error">An error has occured, please try again later. </Alert>
            </Snackbar>

            <Grid
                container
                spacing={2}
                sx={{
                    [theme.breakpoints.up("md")]: {
                        maxWidth: 800,
                        marginLeft: "auto",
                        marginRight: "auto",
                    },
                }}
            >
                {(dataPendingJobs !== undefined) && dataPendingJobs.map((row) => (
                    <Grid item key={row.id} xs={12}>
                        <Card variant="outlined">
                            <CardHeader
                                title={(
                                    <Typography
                                        variant="h6"
                                    >
                                        {`${row.serviceTypeName} - ${row.hostName}`}
                                    </Typography>
                                )}
                                avatar={(
                                    <HomeRepairService
                                        color="primary"
                                    />
                                )}
                                sx={{
                                    backgroundColor: theme.palette.grey.A100,
                                }}
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <StyledGridDetails
                                        item
                                        xs={12}
                                        md
                                    >
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            component="span"
                                            className="label"
                                        >
                                            Location:
                                            {" "}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            component="span"
                                            className="value"
                                        >
                                            {row.propertyAddress}
                                        </Typography>
                                    </StyledGridDetails>
                                    <StyledGridDetails
                                        item
                                        xs={12}
                                        md
                                    >
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            component="span"
                                            className="label"
                                        >
                                            Request Date:
                                            {" "}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            component="span"
                                            className="value"
                                        >
                                            {moment.utc(row.requestDate).tz(userTimezone).format("ll")}
                                        </Typography>
                                    </StyledGridDetails>
                                    <StyledGridActions
                                        item
                                        xs={12}
                                        md={3}
                                    >
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            color="error"
                                            onClick={() => {
                                                setServiceDetailsId(row.id);
                                                setStatusName(row.serviceStatusName);
                                                setProviderRemarks(row.providerRemarks);
                                                setOpenCancellationForm(true);
                                            }}
                                        >
                                            Reject
                                        </Button>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                updateServiceRequest({
                                                    id: row.id,
                                                    serviceStatusId: 1,
                                                    lastUpdatedDateTime: dateNowinUTC,
                                                    providerRemarks: "",
                                                });
                                            }}
                                        >
                                            Accept
                                        </Button>
                                    </StyledGridActions>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
                {isGettingData && (
                    <Grid item xs={12}>
                        <Skeleton
                            variant="rectangular"
                            sx={{
                                height: theme.spacing(4),
                                borderRadius: "4px",
                            }}
                        />
                    </Grid>
                )}
                {(!isGettingData && dataPendingJobs.length === 0) && (
                    <Grid item xs={12}>
                        <EmptyState
                            desc="No Pending Jobs"
                        />
                    </Grid>
                )}
            </Grid>

            <ServiceCancellationForm
                OpenCancellationForm={OpenCancellationModal}
                CloseCancellationForm={handleCloseCancelForm}
                PassFormStyle={style}
                PassIdFromDetails={ServiceDetailsId}
                PassStatusName={StatusName}
                PassSPRemarks={ProviderRemarks}
            />
        </>
    );
}

export default Pendingjobs;
