import * as React from "react";
import { AppBar, Button, Toolbar, Box, Menu, MenuItem, IconButton, ListItemIcon } from "@mui/material";
import { Auth, API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { AccountCircle, DeleteForeverSharp, ExpandMore, Home, Logout } from "@mui/icons-material";
import { clear, showLoader } from "../features/auth/authSlice";
import { STATUS_CODE_PROFILE_COMPLETE } from "../utils/SignUpStatusCode";
import ChatMenuButton from "./websocket/ChatMenuButton";

export default function NavMenu() {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const userInfo = useSelector((state) => state.auth.userInfo);
    const signUpProcessFlow = useSelector((state) => state.auth.signUpProcessFlow);
    const dispatch = useDispatch();

    const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
    const profileOpen = Boolean(profileAnchorEl);

    const logOut = async () => {
        dispatch(clear());
        Auth.signOut();
    };

    const deleteAllUserData = () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("All user data tied to this account will be wiped from our system. Continue?")) {
            dispatch(showLoader({ open: true, message: "Deleting user data... This will take a while." }));
            API.del("ProviderApi", "/deleteAllSpData").then(() => {
                alert("All user data deleted. Account fully wiped.");
                window.location.replace("/");
            }).catch(() => {
                dispatch(showLoader({ open: false, message: "" }));
                alert("Error deleting user data");
            });
        }
    };

    return (
        <Box sx={{ flexgrow: 1 }}>
            {/* <WebSocketComponent /> */}
            <AppBar position="fixed">
                <Toolbar>
                    {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {!isAuthenticated ? (
                            <Button startIcon={<Dashboard />} color="inherit" component={RouterLink} to="/dashboard">LOGO</Button>
                        ) : (
                            (signUpProcessFlow.statusCode === STATUS_CODE_PROFILE_COMPLETE) && (<Button color="inherit" startIcon={<Dashboard />} component={RouterLink} to="/dashboard">Dashboard</Button>)
                        )}
                        Service Provider
                    </Typography> */}

                    {!isAuthenticated ? (
                        <>
                            <Button
                                id="basic-button1"
                                component={RouterLink}
                                color="inherit"
                                to="/signup"
                            >
                                Sign Up

                            </Button>
                            <Button
                                id="basic-button"
                                component={RouterLink}
                                color="inherit"
                                to="/login"
                            >
                                Log In

                            </Button>
                        </>
                    )
                        : (
                            <>
                                <Box sx={{ flexGrow: 1 }}>
                                    {signUpProcessFlow.statusCode === STATUS_CODE_PROFILE_COMPLETE && (
                                        <IconButton
                                            component={RouterLink}
                                            to="/dashboard"
                                        >
                                            <Home />
                                        </IconButton>
                                    )}
                                </Box>
                                {/* <IconButton
                                    component={RouterLink}
                                    to="/messenger"
                                >
                                    <ChatBubble />
                                </IconButton> */}
                                <ChatMenuButton />
                                <Button
                                    startIcon={<AccountCircle />}
                                    endIcon={<ExpandMore />}
                                    onClick={(e) => setProfileAnchorEl(e.currentTarget)}
                                >
                                    {userInfo.firstName}
                                </Button>
                                <Menu
                                    anchorEl={profileAnchorEl}
                                    open={profileOpen}
                                    onClose={() => setProfileAnchorEl(null)}
                                >
                                    {(signUpProcessFlow.statusCode === STATUS_CODE_PROFILE_COMPLETE) && (
                                        <MenuItem
                                            component={RouterLink}
                                            color="inherit"
                                            variant="text"
                                            to="/profile"
                                            onClick={() => setProfileAnchorEl(null)}
                                        >
                                            <ListItemIcon>
                                                <AccountCircle fontSize="small" />
                                            </ListItemIcon>
                                            Hi
                                            {" "}
                                            {userInfo.firstName}
                                        </MenuItem>
                                    )}
                                    <MenuItem onClick={deleteAllUserData}>
                                        <ListItemIcon><DeleteForeverSharp /></ListItemIcon>
                                        Account Wipe
                                    </MenuItem>
                                    <MenuItem
                                        onClick={logOut}
                                    >
                                        <ListItemIcon>
                                            <Logout fontSize="small" />
                                        </ListItemIcon>
                                        {" "}
                                        Logout
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
