import { Send } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, IconButton, LinearProgress, Stack,
    TextField, useTheme,
} from "@mui/material";
import moment from "moment-timezone";
import "../../utils/moment-setup";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useUpdateServiceRequestStatusMutation } from "../../features/auth/spAPI";

function ServiceCancellationForm({
    OpenCancellationForm,
    CloseCancellationForm,
    CloseServiceDetailsForm,
    PassIdFromDetails,
    PassStatusName,
    PassSPRemarks }) {
    const [CancellationTxt, setCancellationTxt] = useState("");
    const theme = useTheme();

    // get timezone from user's settings (or set to local timezone if empty)
    const userTimezone = useSelector((state) => state.auth.userInfo.timeZone) || moment.tz.guess();

    const [updateServiceRequest,
        { isLoading: isLoadingUpdateServiceRequest,
            isError: isErrorUpdateServiceRequest,
            error: errorUpdateServiceRequest,
            isSuccess: isSuccessUpdateServiceRequest,
            reset: resetUpdateServiceRequest }] = useUpdateServiceRequestStatusMutation();

    useEffect(() => {
        if (isSuccessUpdateServiceRequest) {
            setCancellationTxt("");
            if (CloseCancellationForm !== undefined) CloseCancellationForm(false);
            if (CloseServiceDetailsForm !== undefined) CloseServiceDetailsForm(false);
        }
    }, [isSuccessUpdateServiceRequest]);

    const UpdateCancellationFunction = () => {
        if (CancellationTxt !== "") {
            updateServiceRequest({
                id: PassIdFromDetails,
                serviceStatusId: 4,
                lastUpdatedDateTime: moment.utc(),
                providerRemarks: `${PassSPRemarks}${`${moment().tz(userTimezone).format()}`},${PassStatusName},Cancelled,${JSON.stringify(CancellationTxt)}\n`,
            });
        } else {
            if (CloseCancellationForm !== undefined) CloseCancellationForm(false);
            if (CloseServiceDetailsForm !== undefined) CloseServiceDetailsForm(false);
        }
    };

    const isProcessing = isLoadingUpdateServiceRequest;

    return (
        <Dialog
            open={OpenCancellationForm}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                Service Cancellation Form
            </DialogTitle>
            <DialogContent>
                <form>
                    <TextField
                        sx={{ mt: theme.spacing(3) }}
                        multiline
                        minRows={3}
                        fullWidth
                        value={CancellationTxt}
                        onChange={(e) => {
                            setCancellationTxt(e.target.value);
                        }}
                        variant="outlined"
                        label="Reason for Cancellation"
                        disabled={isProcessing}
                        required
                    />
                </form>
            </DialogContent>
            {isProcessing && (<LinearProgress />)}
            <DialogActions>
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => CloseCancellationForm(false)}
                    color="close"
                    disabled={isProcessing}
                >
                    Cancel
                </Button>
                <Button
                    onClick={UpdateCancellationFunction}
                    size="small"
                    variant="contained"
                    disabled={isProcessing || CancellationTxt === ""}
                    endIcon={<Send />}
                >
                    Submit Report to Host
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ServiceCancellationForm;
