import { Refresh } from "@mui/icons-material";
import {
    Button,
    IconButton,
    MenuItem,
    Select,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import { useState } from "react";

export default function RefreshOptions({
    refreshChoices,
    refreshInterval,
    setRefreshInterval,
    refetch,
}) {
    const theme = useTheme();
    const [isDisabled, setIsDisabled] = useState(false);

    const handleRefresh = () => {
        refetch();
        setIsDisabled(true);
        setTimeout(() => {
            setIsDisabled(false);
        }, 2000);
    };

    const handleRefreshIntervalChange = (event) => {
        setRefreshInterval(event.target.value);
    };

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={1.5}
        >
            <IconButton
                color="primary"
                onClick={handleRefresh}
                disabled={isDisabled}
            >
                <Refresh />
            </IconButton>
            {/* <Button
                variant="outlined"
                sx={{
                    backgroundColor: theme.palette.common.white,
                }}
                onClick={handleRefresh}
                disabled={isDisabled}
                startIcon={<Refresh />}
            >
                Refresh
            </Button> */}
            <Typography
                component="span"
                variant="body2"
            >
                Refresh interval:&nbsp;
            </Typography>
            <Select
                value={refreshInterval}
                onChange={handleRefreshIntervalChange}
                size="small"
                sx={{
                    backgroundColor: theme.palette.common.white,
                }}
            >
                {refreshChoices.map((r) => (
                    <MenuItem key={r.interval} value={r.interval}>{ r.text }</MenuItem>
                ))}
            </Select>
        </Stack>
    );
}
