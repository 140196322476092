import { Grid, Divider, useTheme, Typography } from "@mui/material";
import moment from "moment-timezone";
import "../../utils/moment-setup";

/*
    Example input:
    '2024-01-09T11:30:24+08:00,Ongoing,Completed,"this is\\na completed message"'
    Example output:
    ['2024-01-09T11:30:24+08:00', 'Ongoing', 'Completed', 'this is\na completed message']
*/
function parseRemark(remarkString) {
    const [date, lastState, currentState, ...body] = remarkString.split(",");
    return [date, lastState, currentState, JSON.parse(body.join(",") || "\"\"")];
}

function ServiceDetailsRemarks({ remarksString }) {
    const theme = useTheme();
    if (typeof remarksString !== "string") return [];
    const remarksParsed = remarksString.split("\n").map((remarkString) => parseRemark(remarkString));
    return remarksParsed.map(([date, lastState, currentState, body]) => date && lastState && currentState && (
        <Grid container alignItems="top" columnSpacing={1} sx={{ my: 0.5 }}>
            <Grid item xs={12}>
                <span style={{ fontWeight: "bold" }}>
                    {`[${lastState === currentState
                        ? currentState
                        : `${lastState} » ${currentState}`}] `}
                </span>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
            >
                <Typography
                    pl={{
                        xs: theme.spacing(1),
                    }}
                >
                    {body}
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
            >
                <Typography
                    variant="body2"
                    color="text.secondary"
                    pl={{
                        xs: theme.spacing(1),
                    }}
                    textAlign={{
                        xs: "left",
                        md: "right",
                    }}
                >
                    {`${moment.parseZone(date).format("llll zz")} (${moment.parseZone(date).fromNow()})`}
                </Typography>
            </Grid>
        </Grid>
    ));
}

export default ServiceDetailsRemarks;
