import { Box, Button, IconButton, List, ListItem, Paper, Stack, TextField, Typography, useTheme } from "@mui/material";
import { ArrowDownward, Send } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { useGetChatMessageQuery, useLazyGetChatHistoryQuery, useSendChatMessageMutation } from "../../features/auth/wsAPI";

function ScrollToBottom() {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView({
        behavior: "smooth",
    }));
    return <ListItem ref={elementRef} />;
}

export default function MessageHistory({
    selectedHostId,
}) {
    const theme = useTheme();
    const userInfo = useSelector((state) => state.auth.userInfo);
    const localUserTZ = useSelector((state) => state.auth.userInfo.timeZone);
    const [winHeight, setWinHeight] = useState(200);
    const [msgObj, setMsgObj] = useState({
        hostId: 0,
        hostName: "",
        spId: 0,
        spName: "",
        message: "",
        senderUserType: "sp",
    });
    const [messageHistory, setMessageHistory] = useState([]);
    const dateToday = new Date();
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 30;
    const scrollToBottomRef = useRef();
    const [showJumpToBottom, setShowJumpToBottom] = useState(false);
    const [lastViewedListItem, setLastViewedItem] = useState("");

    const [triggerGetChatHistory, {
        data: dataChatHistory,
        isLoading: isLoadingChatHistory,
        isFetching: isFetchingChatHistory,
    }] = useLazyGetChatHistoryQuery();

    const isGettingChatHistory = isLoadingChatHistory || isFetchingChatHistory;

    const {
        data: dataWsReceiveMessage,
    } = useGetChatMessageQuery();

    const [sendChat] = useSendChatMessageMutation();

    const [isSendingMessage, setIsSendingMessage] = useState(false);

    useEffect(() => {
        const winSize = () => {
            setWinHeight(window.innerHeight - 250);
        };

        window.addEventListener("resize", winSize);
        winSize();
        return () => window.removeEventListener("resize", winSize);
    }, []);

    useEffect(() => {
        if (selectedHostId !== undefined || selectedHostId !== 0) {
            setMsgObj((prev) => ({
                ...prev,
                hostId: selectedHostId,
            }));
        }
        Auth.currentAuthenticatedUser().then((e) => {
            const spId = e.attributes["custom:spFK"];
            setMsgObj((prev) => ({
                ...prev,
                spId,
                spName: `${userInfo.firstName} ${userInfo.lastName}`,
            }));
        });

        // resets message history everytime user's selects host
        setCurrentPage(1);
        setMessageHistory([]);
        setShowJumpToBottom(false);

        triggerGetChatHistory({
            recepientId: selectedHostId,
            page: 1,
            pageSize,
        });
    }, [selectedHostId]);

    useEffect(() => {
        if (dataChatHistory) {
            const newChatHistory = [...dataChatHistory.Results];
            const arr = [...messageHistory];

            if (currentPage !== 1) setLastViewedItem(`msgRef-${arr[0].Id}`);
            else setLastViewedItem("");

            newChatHistory.sort((a, b) => b.Id - a.Id).forEach((item) => {
                const check = arr.find((x) => x.Id === item.Id);
                if (check === undefined) arr.splice(0, 0, item);
            });
            setMessageHistory(arr);
        }
    }, [dataChatHistory]);

    // force to scroll to last viewed item after loading next message history page
    useEffect(() => {
        if (lastViewedListItem !== "") {
            document.getElementById(lastViewedListItem).scrollIntoView();
        }
    }, [lastViewedListItem]);

    useEffect(() => {
        if (dataWsReceiveMessage.length > 0) {
            const msgToAdd = dataWsReceiveMessage[dataWsReceiveMessage.length - 1];

            if (msgToAdd.HostId === selectedHostId) {
                setMessageHistory((prev) => [
                    ...prev,
                    msgToAdd,
                ]);
            }

            if (msgToAdd.MessageType === "sender") {
                setIsSendingMessage(false);
                setMsgObj((prev) => ({ ...prev, message: "" }));
            }
        }
    }, [dataWsReceiveMessage]);

    const enableSendButton = msgObj.message.length > 0;

    useEffect(() => {
        if (messageHistory.length > 0 && currentPage === 1 && scrollToBottomRef.current !== null) {
            scrollToBottomRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messageHistory]);

    return (
        <Box
            position="relative"
        >
            {isGettingChatHistory && currentPage === 1 ? (
                <Box
                    height={winHeight}
                >
                    <ListItem>
                        Loading...
                    </ListItem>
                </Box>
            ) : (

                <List
                    dense
                    sx={{
                        overflow: "auto",
                        // minHeight: winHeight,
                        height: winHeight,
                    }}
                    onScroll={(e) => {
                        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
                        const bottomList = Math.abs(scrollHeight - (scrollTop + clientHeight));

                        setShowJumpToBottom(bottomList >= 200);

                        if (scrollTop === 0) {
                            const page = currentPage + 1;
                            setCurrentPage(page);

                            triggerGetChatHistory({
                                recepientId: selectedHostId,
                                page,
                                pageSize,
                            });
                        }
                    }}
                >
                    {messageHistory.map((i, idx) => {
                        const index = idx;
                        const convertedDate = moment.tz(i.DateSent, localUserTZ);
                        let dtSent = convertedDate.format("hh:MM A");

                        if (convertedDate.format("MM/DD/YYYY") !== dateToday.toLocaleDateString()) dtSent = `${convertedDate.format("MM/DD/YYYY")} ${dtSent}`;

                        return (
                            <ListItem
                                key={index}
                                id={`msgRef-${i.Id}`}
                            >
                                <Stack
                                    alignItems={i.MessageType === "sender" ? "flex-end" : "flex-start"}
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <Typography variant="caption">
                                        {dtSent}
                                    </Typography>
                                    <Paper
                                        sx={{
                                            padding: theme.spacing(1),
                                            backgroundColor: i.MessageType === "sender" ? theme.palette.primary.light : theme.palette.grey[300],
                                        }}
                                    >
                                        {i.Message}
                                    </Paper>
                                </Stack>
                            </ListItem>
                        );
                    })}
                    {isGettingChatHistory && <ListItem>Loading...</ListItem>}
                    <ListItem ref={scrollToBottomRef} />
                </List>
            )}
            <Box
                textAlign="center"
                position="absolute"
                bottom={79}
                width="100%"
                display={showJumpToBottom ? "block" : "none"}
            >
                <Button
                    variant="contained"
                    size="small"
                    startIcon={<ArrowDownward />}
                    onClick={() => scrollToBottomRef.current.scrollIntoView({
                        behavior: "smooth",
                    })}
                >
                    Jump to bottom
                </Button>
            </Box>
            <Stack
                direction="row"
                p={theme.spacing(1)}
            >
                <TextField
                    size="small"
                    fullWidth
                    value={msgObj.message}
                    onChange={(e) => setMsgObj((prev) => ({ ...prev, message: e.target.value }))}
                    sx={{
                        backgroundColor: theme.palette.common.white,
                    }}
                    disabled={isSendingMessage}
                />
                <IconButton
                    color="primary"
                    disabled={!enableSendButton || isSendingMessage}
                    onClick={() => {
                        sendChat({
                            msg: msgObj,
                        });
                        setIsSendingMessage(true);
                    }}
                >
                    <Send />
                </IconButton>
            </Stack>
            <Typography
                variant="caption"
                padding={0}
                textAlign="right"
                component="div"
                paddingRight={theme.spacing(7)}
                color={isSendingMessage ? theme.palette.common.black : "transparent"}
            >
                Sending...
            </Typography>
        </Box>
        // </Paper>
    );
}
