import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Card, CardActions, CardContent, CardHeader, Grid, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { API, Auth, Hub } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";
import { setAuthenticationStatus, setSignUpProcess, setLogInProcess, showLoader, setUserInfo } from "../features/auth/authSlice";
import { LOGIN_VERIFYOTP_STEP, SIGNUP_UPDATEPROFILE_STEP, SIGNUP_VERIFYOTP_STEP } from "../utils/enum";
import HouseCard from "./helper/HouseCard";

export default function VerifyOtp({ phoneNumber }) {
    const dispatch = useDispatch();
    const currentSignUpProcess = useSelector((state) => state.auth.signUpProcess);
    const currentLogInProcess = useSelector((state) => state.auth.logInProcess);
    const authenticationType = useSelector((state) => state.auth.authenticationType);
    const [otp, setOtp] = useState("");
    const [errorOtpField, setErrorOtpField] = useState("");
    const [authSession, setAuthSession] = useState(null);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    // useEffect(() => {
    //     setAuthSession(session);
    // }, [session]);

    useEffect(() => {
        const hubListenerCancellation = Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "autoSignIn":
                    // if (authenticationType === "SIGNUP") {
                    //     if (currentSignUpProcess === SIGNUP_VERIFYOTP_STEP) {
                    //         addAndUpdateUserSub();
                    //     }
                    // } else
                    if (currentLogInProcess === LOGIN_VERIFYOTP_STEP) {
                        dispatch(setLogInProcess(""));
                        // dispatch(showLoader({ open: false, message: "" }));
                        window.location.replace("/dashboard");
                    }

                    break;
                default:
                    break;
            }
        });

        // on component unmount
        return () => {
            // cancels listening to amplify auth events
            hubListenerCancellation();
        };
    }, []);

    const handleVerifyOTP = async () => {
        setHasSubmitted(true);
        setErrorOtpField("");
        dispatch(showLoader({ open: true, message: "Verifying your code. Please wait..." }));

        try {
            const confirmResponse = await Auth.confirmSignUp(phoneNumber.replace("+", "").replace(/\s+/g, "").trim(), otp);
            dispatch(setAuthenticationStatus(true));
        } catch (err) {
            if (err.code === "NotAuthorizedException") {
                setErrorOtpField("Invalid verification code.");
                setOtp("");
            } else if (err.message === "Challenge response cannot be empty") {
                setErrorOtpField("OTP cannot be empty");
            } else setErrorOtpField("Error encountered while verifying your OTP. Please try again.");

            dispatch(setAuthenticationStatus(false));
            dispatch(showLoader({ open: false, message: "" }));
        }
    };

    const addAndUpdateUserSub = async () => {
        try {
            // get currrent authenticated user
            const currentUser = await Auth.currentAuthenticatedUser();
            const phoneWithPlus = phoneNumber[0] === "+" ? phoneNumber : `+${phoneNumber}`;

            const init = {
                body: {
                    sub: currentUser.attributes.sub,
                    phoneNumber: phoneWithPlus,
                },
            };
            const userId = await API.post("ProviderApi", "/updateProviderSub", init);

            await Auth.updateUserAttributes(currentUser, {
                "custom:spFK": `${userId}`,
            });

            // SIGNUP: if save cognito user pool id is success, redirect to complete registration page
            window.location.replace("/dashboard");
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(showLoader({ open: false, message: "" }));
            setHasSubmitted(false);
            dispatch(setLogInProcess(""));
        }
    };

    const handleKeyDown = (e) => {
        // if (hasSubmitted) return;
        if (e.key === "Enter") {
            handleVerifyOTP();
        }
    };

    const handleOtpOnChange = (e) => {
        setOtp(e.target.value);
        setErrorOtpField("");
    };

    const handleResendOtp = async () => {
        setOtp("");
        dispatch(showLoader({ open: true, message: "Resending verification code. Please wait..." }));
        setErrorOtpField("");
        try {
            await Auth.resendSignUp(phoneNumber);
        } catch (error) {
            console.log(">>", error);
        } finally {
            dispatch(showLoader({ open: false, message: "" }));
        }
    };

    return (
        <HouseCard title="Verify OTP">
            <Grid container spacing={2}>
                {errorOtpField.trim().length > 0 && (
                    <Grid item xs={12}>
                        <Alert
                            severity="error"
                        >
                            {errorOtpField}
                        </Alert>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <MuiOtpInput
                        value={otp}
                        inputMode="numeric"
                        length={6}
                        onChange={(newValue) => {
                            setOtp(newValue);
                            setErrorOtpField("");
                        }}
                        TextFieldsProps={{
                            size: "small",
                        }}
                        onComplete={() => console.log("complete")}
                        onKeyDown={handleKeyDown}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        onClick={handleVerifyOTP}
                        size="small"
                        fullWidth
                    >
                        Confirm
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button size="small" onClick={() => handleResendOtp()}>
                        Resend Code
                    </Button>
                </Grid>
            </Grid>
        </HouseCard>
    );
}
