import { Backdrop, LinearProgress, Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";

export default function LoaderComponent() {
    const loader = useSelector((state) => state.auth.loader);

    return (
        <Backdrop open={loader.open} sx={{ zIndex: (theme) => theme.zIndex.drawer + 300 }}>
            <Paper sx={{ padding: 4 }}>
                <Typography variant="body2" component="p">
                    {loader.message}
                </Typography>
                <LinearProgress />
            </Paper>
        </Backdrop>
    );
}
