import { HomeRepairService } from "@mui/icons-material";
import { Button, Card, CardContent, CardHeader, Grid, Skeleton, styled, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showLoader } from "../../features/auth/authSlice";
import { useGetHostInvitesQuery, useUpdateInviteMutation } from "../../features/auth/spAPI";
import EmptyState from "../helper/EmptyState";

const StyledGridDetails = styled(Grid)(({ theme }) => ({
    ".label": {
        display: "block",
        // [theme.breakpoints.up("sm")]: {
        //     display: "inline",
        // },
    },
    ".value": {
        display: "block",
        paddingLeft: theme.spacing(1),
        // [theme.breakpoints.up("sm")]: {
        //     display: "inline",
        // },
    },
}));

const StyledGridActions = styled(Grid)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    button: {
        width: "46%",
        // [theme.breakpoints.up("md")]: {
        //     width: "100%",
        // },
    },
}));

function Hostinvites({ HostInvitesTabCount }) {
    const [SPHostData, setSPHostData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const {
        data: dataHostInvites,
        isLoading: isLoadingHostInvites,
        isFetching: isFetchingHostInvites,
        isSuccess: isSuccessHostInvites,
        isError: isErrorHostInvites } = useGetHostInvitesQuery();

    const [updateInvitation,
        { isLoading: isLoadingUpdateInvitation,
            isError: isErrorUpdateInvitation,
            error: errorUpdateInvitation,
            isSuccess: isSuccessUpdateInvitation,
            reset: resetUpdateInvitation }] = useUpdateInviteMutation();

    const theme = useTheme();

    useEffect(() => {
        if (isLoadingUpdateInvitation) { dispatch(showLoader({ open: true, message: "Please wait..." })); } else { dispatch(showLoader({ open: false, message: "" })); }
    }, [isLoadingUpdateInvitation]);

    const isGettingData = isLoadingHostInvites || isFetchingHostInvites;

    return (
        <Grid
            container
            spacing={2}
            sx={{
                [theme.breakpoints.up("md")]: {
                    maxWidth: 800,
                    marginLeft: "auto",
                    marginRight: "auto",
                },
            }}
        >
            {(!isGettingData && dataHostInvites.map((row) => (
                <Grid item key={row.id} xs={12}>
                    <Card variant="outlined">
                        <CardHeader
                            title={(
                                <Typography
                                    variant="h6"
                                >
                                    {`${row.serviceTypeName} - ${row.hostName}`}
                                </Typography>
                            )}
                            avatar={(
                                <HomeRepairService
                                    color="primary"
                                />
                            )}
                            sx={{
                                backgroundColor: theme.palette.grey.A100,
                            }}
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <StyledGridDetails
                                    item
                                    xs={12}
                                    md
                                >
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        component="span"
                                        className="label"
                                    >
                                        Location:
                                        {" "}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        component="span"
                                        className="value"
                                    >
                                        {row.propertyAddress}
                                    </Typography>
                                </StyledGridDetails>
                                <StyledGridDetails
                                    item
                                    xs={12}
                                    md
                                >
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        component="span"
                                        className="label"
                                    >
                                        Request Date:
                                        {" "}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        component="span"
                                        className="value"
                                    >
                                        {`${row.convertedDateOfRequest} @ ${row.convertedTimeOfRequest}`}
                                    </Typography>
                                </StyledGridDetails>
                                <StyledGridActions
                                    item
                                    xs={12}
                                    md
                                >
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color="error"
                                        onClick={() => {
                                            updateInvitation({
                                                id: row.id,
                                                status: 2,
                                            });
                                        }}
                                    >
                                        Reject Invite
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                            updateInvitation({
                                                id: row.id,
                                                status: 1,
                                            });
                                        }}
                                    >
                                        Accept Invite
                                    </Button>
                                </StyledGridActions>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            )))}
            {isGettingData && (
                <Grid item xs={12}>
                    <Skeleton
                        variant="rectangular"
                        sx={{
                            height: theme.spacing(4),
                            borderRadius: "4px",
                        }}
                    />
                </Grid>
            )}
            {!isGettingData && dataHostInvites.length === 0 && (
                <Grid item xs={12}>
                    <EmptyState
                        desc="No Invites"
                    />
                </Grid>
            )}
        </Grid>
    );
}

export default Hostinvites;
