import { Box, Button, Typography, Grid, Alert, useTheme, CardContent, Card, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { MuiTelInput } from "mui-tel-input";
import PhoneInput from "./helper/react-phone-input-2";
import { setLogInProcess, showLoader } from "../features/auth/authSlice";
import CustomPasswordField from "./shared/custom_password";
import { LOGIN_FORCE_PASSWORD_CHANGE, LOGIN_STEP } from "../utils/enum";
import ChangePassword from "./ChangePassword";
import CustomPhoneInput from "./shared/custom_phoneinput";
import HouseCard from "./helper/HouseCard";
import { useLazyGetUserProfileQuery } from "../features/auth/spAPI";

export default function Login() {
    const theme = useTheme();
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [currentUser, setCurrentUser] = useState(null);
    const currentLogInProcess = useSelector((state) => state.auth.logInProcess);
    const dispatch = useDispatch();
    const [errorPhoneField, setErrorPhoneField] = useState("");
    const { route } = useAuthenticator((context) => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();
    const from = location.state?.from?.pathname || "/";
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [formIsComplete, setFormIsComplete] = useState(false);
    const [triggerGetProfile, {
        data: dataGetUserProfile,
    }] = useLazyGetUserProfileQuery();

    useEffect(() => {
        if (route === "authenticated") {
            navigate("/dashboard");
            dispatch(setLogInProcess(""));
        } else {
            dispatch(setLogInProcess(LOGIN_STEP));
        }
    }, [route, navigate, dispatch]);

    useEffect(() => {
        // form validation
        if (phone === null) return;
        if (phone.length !== 0
            && password.length !== 0) setFormIsComplete(true);
        else setFormIsComplete(false);
    }, [phone, password]);

    const handleLogIn = async () => {
        setHasSubmitted(true);
        dispatch(showLoader({ open: true, message: "Please wait..." }));
        setErrorPhoneField("");
        try {
            const loginUser = await Auth.signIn(phone.replace("+", "").replace(/\s+/g, "").trim(), password);
            if (loginUser.challengeName === "NEW_PASSWORD_REQUIRED") {
                dispatch(setLogInProcess(LOGIN_FORCE_PASSWORD_CHANGE));
                setCurrentUser(loginUser);
            } else {
                // window.location.replace("/dashboard");
                // await triggerGetProfile();
                // navigate("/dashboard");
                triggerGetProfile()
                    .then((r) => {
                        window.location.replace(r.data.redirectTo);
                        console.log("test", r.data);
                    });
            }
        } catch (error) {
            if (error.name === "NotAuthorizedException") setErrorPhoneField("Incorrect password.");
            else setErrorPhoneField(error.message);
        } finally {
            dispatch(showLoader({ open: false, message: "" }));
        }
    };

    // useEffect(() => {
    //     console.log("login.dataGetUserProfile", dataGetUserProfile);
    //     if (dataGetUserProfile !== undefined) window.location.replace(dataGetUserProfile.redirectTo);
    // }, [dataGetUserProfile]);

    // const handleEnterKeyPress = () => {
    //     if (hasSubmitted) return;
    //     handleLogIn();
    // };

    return (
        <HouseCard
            title="Login"
        >
            {currentLogInProcess === LOGIN_STEP && (
                <Grid container spacing={2}>
                    {/* <Grid item xs={12}>
                        <Typography align="center" variant="h6" sx={{ marginBottom: theme.spacing(2) }}>Login</Typography>
                    </Grid> */}
                    {(errorPhoneField !== "") && (
                        <Grid item xs={12}>
                            <Alert severity="error" onClose={() => setErrorPhoneField("")}>{errorPhoneField}</Alert>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <CustomPhoneInput
                            label="Phone"
                            fullWidth
                            onChange={(e) => {
                                setPhone(e);
                                setErrorPhoneField("");
                            }}
                            // onKeyPress={handleEnterKeyPress}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomPasswordField
                            label="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key !== "Enter") return;
                                handleLogIn();
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            size="small"
                            fullWidth
                            onClick={() => handleLogIn()}
                            disabled={!formIsComplete}
                        >
                            Login
                        </Button>
                    </Grid>
                    <Grid item xs={12} textAlign="right">
                        <Button
                            variant="text"
                            onClick={() => navigate("/forgot-password")}
                        >
                            Forgot your password?
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Typography variant="caption" component="div">
                            Need an account?&nbsp;
                            <Link to="/signup">Sign Up</Link>
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {currentLogInProcess === LOGIN_FORCE_PASSWORD_CHANGE && (
                <ChangePassword
                    currentUser={currentUser}
                />
            )}
        </HouseCard>
    );
}
