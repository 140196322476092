export const ServiceTypes = [
    {
        type: "Cleaning",
        value: 0,
    },
    {
        type: "Pest Control",
        value: 1,
    },
    {
        type: "Electrician",
        value: 2,
    },
    {
        type: "Plumber",
        value: 3,
    },
];

export const GetServiceTypeNameById = (id) => {
    const idx = ServiceTypes.findIndex((x) => x.value === id);

    if (idx < 0) return "";

    return ServiceTypes[idx].type;
};

export const LOGIN_STEP = "Log In";
export const LOGIN_VERIFYOTP_STEP = "Verify OTP";
export const LOGIN_FORCE_PASSWORD_CHANGE = "FORCE_PASSWORD_CHANGE";

export const SIGNUP_STEP = "Sign Up";
export const SIGNUP_VERIFYOTP_STEP = "Verify OTP";
export const SIGNUP_UPDATEPROFILE_STEP = "Update Profile";
