export const SERVICE_STATUS_PENDING_CONFIRMATION = 0;
export const SERVICE_STATUS_CONFIRMED_ACCEPTED = 1;
export const SERVICE_STATUS_ONGOING = 2;
export const SERVICE_STATUS_DONE = 3;
export const SERVICE_STATUS_CANCELLED = 4;
export const SERVICE_STATUS_PERMANENTLY_CANCELLED = 4;

// sx={{ backgroundColor: "#FFFFFF",
//     ...(row.serviceStatusId === 0
//     && { backgroundColor: "#d3d3d3", p: theme.spacing(1), mt: "10px", "&:hover": { backgroundColor: "#a9a9a9" } }),
//     ...(row.serviceStatusId === 1
//     && { backgroundColor: "#4caf50", p: theme.spacing(1), mt: "10px", "&:hover": { backgroundColor: "#81c784" } }),
//     ...(row.serviceStatusId === 2
//     && { backgroundColor: "#2196f3", p: theme.spacing(1), mt: "10px", "&:hover": { backgroundColor: "#1976d2" } }),
//     ...(row.serviceStatusId === 3
//     && { backgroundColor: "#1b5e20", p: theme.spacing(1), mt: "10px", "&:hover": { backgroundColor: "#388e3c" } }),
//     ...((row.serviceStatusId === 4 || row.serviceStatusId === 5)
//     && { backgroundColor: "#f44336", p: theme.spacing(1), mt: "10px", "&:hover": { backgroundColor: "#d32f2f" } }),
// }}

export const getServiceStatusColorCode = (code) => {
    switch (code) {
        case SERVICE_STATUS_PENDING_CONFIRMATION:
            return "#757575";
        case SERVICE_STATUS_CONFIRMED_ACCEPTED:
            return "#4caf50";
        case SERVICE_STATUS_ONGOING:
            return "#2196f3";
        case SERVICE_STATUS_DONE:
            return "#1b5e20";
        case SERVICE_STATUS_CANCELLED:
            return "#f44336";
        default: // SERVICE_STATUS_PERMANENTLY_CANCELLED
            return "#ffffff";
    }
};
