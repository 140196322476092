import { CircularProgress, Container, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { finishInitialLoad, setAuthenticationStatus } from "../features/auth/authSlice";
import { useGetUserProfileQuery } from "../features/auth/spAPI";
import LoaderComponent from "./helper/LoaderComponent";
import NavMenu from "./NavMenu";
import { STATUS_CODE_PROFILE_COMPLETE } from "../utils/SignUpStatusCode";

export default function Layout() {
    const routerLoaderData = useLoaderData();
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [skipGetProfile, setSkipGetProfile] = useState(true);
    const {
        data: dataUserProfile,
        isLoading: isLoadingUserProfile,
        isFetching: isFetchingUserProfile,
    } = useGetUserProfileQuery(null, {
        skip: skipGetProfile,
    });
    const isGettingUserProfile = isLoadingUserProfile || isFetchingUserProfile;
    // const [triggerGetProfile, { data: dataUserProfile }] = useLazyGetUserProfileQuery();

    useEffect(() => {
        if (routerLoaderData) {
            const { auth, user, redirectTo } = routerLoaderData;
            try {
                if (auth) {
                    dispatch(setAuthenticationStatus(true));
                    // triggerGetProfile();
                    setSkipGetProfile(false);
                } else {
                    dispatch(setAuthenticationStatus(false));
                }
            } catch (error) {
                console.log(">>", error);
            }
        }
        dispatch(finishInitialLoad());
    }, [routerLoaderData]);

    useEffect(() => {
        if (dataUserProfile !== undefined) {
            if (dataUserProfile.profile.signUpStatusCode === STATUS_CODE_PROFILE_COMPLETE) navigate(location.pathname);
            else navigate(dataUserProfile.redirectTo);
        }
    }, [dataUserProfile]);
    if (routerLoaderData === null) {
        return (
            <>
                <LoaderComponent />
                <Container sx={{ paddingTop: theme.spacing(10), minHeight: "100vh" }} maxWidth="lg">
                    <Outlet />
                </Container>
            </>
        );
    }

    if (routerLoaderData !== null && isGettingUserProfile) {
        return (
            <Container
                sx={{
                    paddingTop: theme.spacing(20),
                    minHeight: "100vh",
                    textAlign: "center",
                }}
                maxWidth="lg"
            >
                <CircularProgress />
            </Container>
        );
    }

    if (routerLoaderData !== null && !isGettingUserProfile) {
        return (
            <>
                <NavMenu />
                <LoaderComponent />
                <Container sx={{ paddingTop: theme.spacing(10), minHeight: "97vh" }} maxWidth="lg">
                    <Outlet />
                </Container>
            </>
        );
    }
}
